import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useFiltersSolicitudPermisosStore = defineStore({
  id: 'filtersSolicitudPermisos',
  state: () => ({
    _filters: {
      nombre: '',
      cedula: '',
      area: '',
      cargo: '',
      motivo: '',
      tipoFecha: '',
      fechaInicial: '',
      fechaFinal: '',
      estadoSolictud: ''
    },
    _refrescarListadoSolicitudes: false
  }),
  getters: {
    getFilters: (state) => state._filters,
    getRefrescarListadoSolicitudes: (state) => state._refrescarListadoSolicitudes
  },
  actions: {
    setUpdateFilters (filters) {
      this._filters = filters
    },
    setRefrescarListadoSolicitudes (payload) {
      this._refrescarListadoSolicitudes = payload
    }
  }
})
