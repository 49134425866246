import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useSolicitudPermisosStore = defineStore({
  id: 'solicitudPermisos',
  state: () => ({
    _selectedSolicitudes: []
  }),
  getters: {
    getSelectedSolicitudes: (state) => state._selectedSolicitudes
  },
  actions: {
    setSelectedSolicitudes (payload) {
      this._selectedSolicitudes = payload
    }
  }
})
