<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import SolicitudService from '../../../services/solicitud.service'
import Filters from '../filters/index.vue'
import ModalSoportes from '../../components/modalSoportes.vue'
import ModalAprobacion from '../../components/modalAprobacion.vue'
import SidebarSolicitud from '../../components/sidebarSolicitud.vue'
import { useFiltersSolicitudPermisosStore } from '../../../stores/filtersSolicitudPermisos'
import { useSolicitudPermisosStore } from '../../../stores/solicitudPermisos'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { error, warning } from '../../../../../../../libs/mensajes'
import Icon from '@/components/Icon/index.vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
// import { isEqual } from 'lodash'

const toast = useToast()
const store = useStore()
const dt = ref()
const route = useRoute()
const router = useRouter()
const filtersSolicitudPermisosStore = useFiltersSolicitudPermisosStore()
const solicitudPermisosStore = useSolicitudPermisosStore()
const _SolicitudService = ref(new SolicitudService())
const listSolicitudes = ref([])
const limit = ref(20)
const offset = ref(0)
const orderField = ref('createdAt')
const sortOrder = ref(-1)
const openModalSoportes = ref()
const openModalAprobacion = ref()
const openSidebarSolicitud = ref()
const op = ref()
const infoAprobadores = ref([])
const solicitudIdToggle = ref(null)
const selectedSolicitudes = ref([])

// Visualizar tabla de aprobadores de la solicitud
const toggleAprobadores = (event, data) => {
  infoAprobadores.value = data.aprobadores
  solicitudIdToggle.value = data.id
  op.value.toggle(event)
}

// Obtener la información del usuario
const getUser = computed(() => {
  return store.getters['auth/user']
})

// Obtener la información del usuario
const getPeople = computed(() => {
  return store.getters['auth/getPeople']
})

// Obtener los permisos del usuario
const getPermissions = computed(() => {
  return store.getters['auth/getPermissions']
})

// Computada para obtener el store de filtros
const getterFiltersSolicitudPermisos = computed(() => {
  return filtersSolicitudPermisosStore.getFilters
})

// Función que permite la actualización de parámetros de consulta de manera dinámica antes de realizar la navegación a una nueva URL.
const _routerPush = (obj = {}) => {
  const queryParams = route.query
  router.push({ query: { ...queryParams, ...obj } })
}

// Ordenar por campo
const orderQuery = ({ sortField, sortOrder }) => {
  _routerPush({ order: `${sortField},${sortOrder}` })
}

// Paginación
const onPage = (row) => {
  const queryParams = { ...route.query }
  limit.value = row.rows
  offset.value = row.first

  // _routerPush({ ...queryParams, limit: row.rows, offset: row.first })
  router.push({ query: { ...queryParams, limit: row.rows, offset: row.first } })
}

// Asignacion de los query params
const setQueryParams = () => {
  const queryParams = { ...route.query }
  limit.value = queryParams.limit ? parseInt(queryParams.limit) : 20
  offset.value = queryParams.offset ? parseInt(queryParams.offset) : 0
  queryParams.limit = queryParams.limit ? queryParams.limit : 20
  queryParams.offset = queryParams.offset ? queryParams.offset : 0
  queryParams.order = queryParams.order ? queryParams.order : 'createdAt,-1'

  // Split a la cadena para obtener el campo y el orden
  const splitOrder = queryParams.order.split(',')
  orderField.value = splitOrder[0]
  sortOrder.value = parseInt(splitOrder[1])

  return queryParams
}

// Obtener el listado de solicitudes
const fetchListSolicitudes = async () => {
  await _SolicitudService.value.listarSolicitudes(setQueryParams()).then((res) => {
    listSolicitudes.value = res.data

    // Dependiendo del usuario aprobador, comparar con los aprobadores y ver si ya aprobo o no la solicitud
    listSolicitudes.value.data = listSolicitudes.value.data.map(sol => {
      sol.aprobadorGestiono = null
      if (sol.esAprobadorSolicitud) {
        const existeAprobador = sol.aprobadores.find(aprob => aprob.aprobadorId === getUser.value.base)
        if (existeAprobador) {
          sol.aprobadorGestiono = existeAprobador.aprobado !== null
        } else {
          sol.aprobadorGestiono = null
        }
      }
      return sol
    })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Exportar a excel
const exportarExcel = async () => {
  // Obtener los filtros
  const queryParams = { ...route.query }

  await _SolicitudService.value.exportarExcel(queryParams).then((url) => {
    window.open(url, '_blank')
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Funcion para validar si la solicitud ya fue gestionada al menos por un aprobador
const validarSolicitudGestionada = (aprobadores) => {
  return aprobadores.some((aprob) => {
    console.log('aprob', aprob)
    return aprob.aprobacionId !== null && aprob.aprobado !== null
  })
}

// Editar solicitud
const editarSolicitud = (data) => {
  const { id, aprobadores } = data

  if (validarSolicitudGestionada(aprobadores)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'Esta solicitud ya fue gestionada por un aprobador, no se puede editar'
    })
    return
  }

  router.push({ name: 'pharmasan.administrativa.solicitud-permisos.editar', params: { id } })
}

// Eliminar solicitud
const eliminarSolicitud = (data) => {
  const { id, aprobadores } = data

  if (validarSolicitudGestionada(aprobadores)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'Esta solicitud ya fue gestionada por un aprobador, no se puede eliminar'
    })
    return
  }

  if (id) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Desea eliminar esta solicitud de permiso?, esta acción no se puede revertir.',
      showDenyButton: true,
      denyButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, Eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        _SolicitudService.value.eliminarSolicitud(id).then(({ status }) => {
          if (status !== 200) return

          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'La solicitud de permiso se ha eliminado correctamente'
          }).then(() => {
            fetchListSolicitudes()
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    console.error('Error al eliminar la solicitud, por favor intente nuevamente')
  }
}

// Ver solicitud
const verSolicitud = (data) => {
  openSidebarSolicitud.value.openSidebar(data)
}

// Funcion para abrir el modal de soportes
/* const verSoportes = (dataSolicitud) => {
  openModalSoportes.value.openModal(dataSolicitud)
} */

// Funcion para enviar la solicitud
const enviarSolicitud = (data) => {
  const { id } = data

  // Si no tiene aprobadores, no se puede enviar
  if (data.aprobadores.length === 0) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      html: `
        <div class="flex flex-col">
          <span class="">Esta solicitud no tiene aprobadores, no se puede enviar!</span>
          <p class="text-xs mt-4">
            <span class="font-semibold">Información:</span>
            <span>
              Contacte con el administrador del módulo para que le asigne aprobadores y pueda enviar la solicitud.
            </span>
          </p>
        </div>
      `
    })
    return
  }

  if (id === null) {
    error('No se puede enviar la solicitud, por favor intente nuevamente')
    return
  }

  Swal.fire({
    icon: 'warning',
    title: 'Advertencia',
    html: `
      <div class="flex flex-col">
        <span class="">Desea enviar esta solicitud de permiso?, esta acción no se puede revertir!</span>
        <p class="text-xs mt-4">
          <span class="font-semibold">Nota:</span>
          <span>
            Si la solicitud es enviada, no se podrá editar ni eliminar. Puede revisar su solicitud dando clic
            <span
              id="verSolicitudLink"
              class="text-blue-600 font-bold underline cursor-pointer"
            >AQUÍ</span>&nbsp; para verificar la información a enviar.
          </span>
        </p>
      </div>
    `,
    showDenyButton: true,
    allowOutsideClick: false,
    denyButtonText: 'No, Cancelar',
    confirmButtonText: 'Si, Enviar'
  }).then((result) => {
    if (result.isConfirmed) {
      _SolicitudService.value.enviarSolicitud(id, data).then(({ status }) => {
        if (status !== 200) return

        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: 'La solicitud de permiso se ha enviado correctamente'
        }).then(() => {
          fetchListSolicitudes()
        })
      }).catch((err) => {
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    }
  })

  document.getElementById('verSolicitudLink').addEventListener('click', () => {
    // verSolicitud(data)
    router.push({ name: 'pharmasan.administrativa.solicitud-permisos.editar', params: { id: data.id } })
  })
}

// Funcion para aprobar o rechazar la solicitud
const aprobarSolicitud = (data, estado) => {
  openModalAprobacion.value.openModal(data, estado)
}

// Funcion para reiniciar la solicitud
/* const restaurarSolicitud = (data) => {
  // Obtener la información del aprobador
  const infoAprobacion = data.aprobadores.find(aprob => aprob.aprobadorId === getUser.value.base)
  // Agregar el id de la solicitud
  infoAprobacion.solicitudPermisoId = data.id

  // Si existe aprobacionId, restaurar la solicitud
  if (infoAprobacion.aprobacionId !== null) {
    Swal.fire({
      icon: 'warning',
      title: 'Advertencia',
      text: 'Desea restaurar esta solicitud de permiso?',
      showDenyButton: true,
      denyButtonText: 'No, Cancelar',
      confirmButtonText: 'Si, Restaurar'
    }).then((result) => {
      if (result.isConfirmed) {
        _SolicitudService.value.restaurarSolicitud(infoAprobacion).then(({ status }) => {
          if (status !== 200) return

          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'La solicitud de permiso se ha restaurado correctamente'
          }).then(() => {
            fetchListSolicitudes()
          })
        }).catch((err) => {
          console.error(err.message)
          if (err.response.status) {
            error(err.response.data.message)
          }
        })
      }
    })
  } else {
    error('No tiene permisos para restaurar esta solicitud')
  }
} */

// Validar de que existan solicitudes seleccionadas
const validarSolicitudesSeleccionadas = () => {
  if (selectedSolicitudes.value.length === 0) {
    toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'Debe seleccionar al menos una solicitud de permiso', life: 5000 })
    return false
  }

  return true
}

// Funcion para validar si el empleado es chequeador y si ya chequeo la solicitud
const shouldShowCheck = () => {
  return selectedSolicitudes.value.some((sol) => {
    return sol.chequeadores.some((cheq) => {
      return cheq.chequeadorId === getUser.value.base
    })
  })
}

// Verificar masivo
const verificarMasivo = () => {
  if (!validarSolicitudesSeleccionadas()) return

  const solicitudesChequeadas = selectedSolicitudes.value.filter((sol) => {
    return sol.chequeadores.some((cheq) => {
      return cheq.chequeadorId === getUser.value.base
    })
  })

  // Obtener las solicitudes que aún no han sido chequeadas por el usuario
  const solicitudesNoChequeadas = selectedSolicitudes.value.filter((sol) => {
    return !sol.chequeadores.some((cheq) => {
      return cheq.chequeadorId === getUser.value.base
    })
  })

  // Obtener los ids de las solicitudes que ya fueron chequeadas
  const listadoSolicitudesChequeadas = solicitudesChequeadas.map((sol) => {
    return `<span class="text-base">${sol.id}</span>`
  }).join(', ')

  // Obtener los ids de las solicitudes que aún no han sido chequeadas
  const listadoSolicitudesNoChequeadas = solicitudesNoChequeadas.map((sol) => {
    return `<span class="text-base">${sol.id}</span>`
  }).join(', ')

  if (shouldShowCheck()) {
    // Si no hay solicitudes para chequear por el usuario logueado retornar un mensaje
    if (solicitudesNoChequeadas.length === 0) {
      toast.add({ severity: 'info', summary: 'Información', detail: 'Las solicitudes de permiso seleccionadas ya fueron verificadas', life: 5000 })
      return
    }

    Swal.fire({
      icon: 'info',
      title: 'Información',
      html: `
        <div class="flex flex-col">
          <span class="text-base">Usted ya ha verificado las siguientes solicitudes de permiso con IDs:</span>
          <p class="text-base font-semibold">
            ${listadoSolicitudesChequeadas}
          </p>
          <hr class="my-4">
          <span class="text-base">Por tal motivo solo se verificarán las siguientes solicitudes de permiso con IDs:</span>
          <p class="font-semibold">
            ${listadoSolicitudesNoChequeadas}
          </p>
        </div>
      `,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: 'Si, verificar',
      confirmButtonColor: '#16A34A',
      input: 'textarea',
      inputPlaceholder: 'Si desea puede ingresar las observaciones de la verificación (opcional)',
      inputAttributes: {
        'aria-label': 'Si desea puede ingresar las observaciones de la verificación (opcional)'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          observacion: result.value,
          solicitudes: solicitudesNoChequeadas.map((sol) => {
            return {
              solicitudPermisoId: sol.id,
              chequeadorId: getUser.value.base,
              chequeadorNombre: getPeople.value.full_name
            }
          })
        }

        _SolicitudService.value.crearChequeoMasivo(data).then(({ status, data: response }) => {
          if (status !== 200) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Problemas al dar la verificación a las solicitudes, consulte con el administrador del sistema'
            })
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Exito',
              text: 'Se han verificado las solicitudes correctamente'
            }).then(() => {
              fetchListSolicitudes()
              selectedSolicitudes.value = []
              solicitudPermisosStore.setSelectedSolicitudes([])
            })
          }
        }).catch((err) => {
          console.error(err.message)
          error(err.response.data.message)
        })
      }
    })
  } else {
    Swal.fire({
      icon: 'info',
      title: 'Información',
      html: `
        <div class="flex flex-col">
          <span class="text-base">Desea verificar masivamente las solicitudes de permiso con IDs:</span>
          <p class="font-semibold">
            ${listadoSolicitudesNoChequeadas}
          </p>
        </div>
      `,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: 'No, cancelar',
      confirmButtonText: 'Si, verificar',
      confirmButtonColor: '#16A34A',
      input: 'textarea',
      inputPlaceholder: 'Si desea puede ingresar las observaciones de la verificación (opcional)',
      inputAttributes: {
        'aria-label': 'Si desea puede ingresar las observaciones de la verificación (opcional)'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          observacion: result.value,
          solicitudes: solicitudesNoChequeadas.map((sol) => {
            return {
              solicitudPermisoId: sol.id,
              chequeadorId: getUser.value.base,
              chequeadorNombre: getPeople.value.full_name
            }
          })
        }

        _SolicitudService.value.crearChequeoMasivo(data).then(({ status, data: response }) => {
          if (status !== 200) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Problemas al dar la verificación a las solicitudes, consulte con el administrador del sistema'
            })
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Exito',
              text: 'Se han verificado las solicitudes correctamente'
            }).then(() => {
              fetchListSolicitudes()
              selectedSolicitudes.value = []
              solicitudPermisosStore.setSelectedSolicitudes([])
            })
          }
        }).catch((err) => {
          console.error(err.message)
          error(err.response.data.message)
        })
      }
    })
  }
}

// Descargar PDF
const descargarPdf = async (data) => {
  const { id } = data

  await _SolicitudService.value.descargarPdf(id).then((url) => {
    if (id !== null) {
      window.open(url, '_blank')
    } else {
      warning('No hay PDF')
    }
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Funcion para retornar el router query de la URL
const getRouteQuery = async () => {
  console.log('getRouteQuery', route.query)
  // Si el objeto no viene vacio
  if (Object.keys(route.query).length !== 0) {
    delete route.query.limit
    delete route.query.offset
    delete route.query.order

    // Parsear los valores de los filtros a enteros
    Object.keys(route.query).forEach((key) => {
      if (['area', 'motivo', 'cargo'].includes(key)) {
        // Si el valor es vacío o string, no se parsea
        if (route.query[key] !== '' && !isNaN(route.query[key])) {
          route.query[key] = parseInt(route.query[key])
        }
      }
    })

    filtersSolicitudPermisosStore.setUpdateFilters(route.query)
  } else {
    filtersSolicitudPermisosStore.setUpdateFilters({})
  }
}

// Funcion para obtener y manejar mejor el estado de la solicitud
const getStatusInfo = (data) => {
  switch (data.estadoSolicitud) {
    case 'APROBADO':
      return {
        class: 'bg-green-100 text-green-800 border-green-400',
        text: data.estadoSolicitud
      }
    case 'RECHAZADO':
      return {
        class: 'bg-red-100 text-red-800 border-red-400',
        text: data.estadoSolicitud
      }
    case 'CANCELADO':
      return {
        class: 'bg-pink-100 text-pink-800 border-pink-400',
        text: data.estadoSolicitud
      }
    case 'SIN APROBADOR':
      return {
        class: 'bg-gray-100 text-gray-800 border-gray-400',
        text: data.estadoSolicitud
      }
    case 'SIN ENVIAR':
      return {
        class: 'bg-blue-100 text-blue-800 border-blue-400',
        text: data.estadoSolicitud
      }
    default:
      return {
        class: 'bg-yellow-100 text-yellow-800 border-yellow-400',
        text: data.estadoSolicitud
      }
  }
}

// Funcion para mostrar los botones de aprobar, rechazar y cancelar
const shouldShowApprovalButtons = (data) => {
  return data.esAprobadorSolicitud && ([null, false].includes(data.aprobadorGestiono) && !data.esCancelada)
}

// Funcion para mostrar la información del estado de la solicitud
const shouldShowStatusInfo = (data) => {
  return !data.esAprobadorSolicitud || ![null, false].includes(data.aprobadorGestiono) || data.esCancelada
}

// Si el usuario logueado tiene permiso de editar solicitudes de permisos
const shouldShowEdit = () => {
  return getPermissions.value.includes('pharmasan.administrativa.solicitud-permisos.editar')
}

// Funcion para permitir restaurar la solicitud si ya fue gestionada por el aprobador
/* const shouldShowRestore = (data) => {
  return data.esAprobadorSolicitud && ![null, false].includes(data.aprobadorGestiono) && !data.esCancelada
} */

// Funcion para solo visualizar el estado sin información de aprobadores
const shouldShowStatusOnly = (data) => {
  return !['SIN ENVIAR', 'CANCELADO'].includes(data.estadoSolicitud)
}

// Funcion para permitir editar y eliminar la solicitud si no ha sido enviada y sea del mismo empleado
const shouldShowEditAndDelete = (data) => {
  return !data.enviada && getUser.value.base === data.empleadoId
}

// Funcion para ver el boton de enviar solicitud
const shouldShowSend = (data) => {
  return !data.enviada && getUser.value.base === data.empleadoId
}

// Watch si cambian los filtros
watch(getterFiltersSolicitudPermisos, async (newFilter) => {
  _routerPush(newFilter)
})

// Watch si cambian los parámetros de la URL
watch(() => route.query, async (value) => {
  await fetchListSolicitudes()
})

// Watch para refrescar la tabla
watch(() => filtersSolicitudPermisosStore.getRefrescarListadoSolicitudes, async (value) => {
  if (value) {
    await fetchListSolicitudes()
    filtersSolicitudPermisosStore.setRefrescarListadoSolicitudes(false)
    solicitudPermisosStore.setSelectedSolicitudes([])
    selectedSolicitudes.value = []
  }
})

// Watch si cambia selectedSolicitudes y agregarlo al store
watch(selectedSolicitudes, async (value) => {
  solicitudPermisosStore.setSelectedSolicitudes(value)
})

// Hook onMounted
onMounted(async () => {
  // Agregar el límite, offset y order por defecto en la URL
  // _routerPush(filtersSolicitudPermisosStore.getFilters)
  await getRouteQuery()
  await fetchListSolicitudes()
  solicitudPermisosStore.setSelectedSolicitudes([])
  selectedSolicitudes.value = []
})
</script>

<template>
  <Toast />
  <div class="box intro-x">
    <div class="mt-4">
      <!-- Titulo Card -->
      <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
        <h3 class="mr-auto capitalize text-base font-medium">Listado</h3>
        <div class="flex">
          <Button
            v-if="$can('pharmasan.administrativa.solicitud-permisos.check')"
            icon="pi pi-check"
            size="small"
            class="mr-2"
            severity="secondary"
            label="Verificar Masivo"
            @click="verificarMasivo"
            style="height: 30px"
          />
          <Button
            icon="pi pi-external-link"
            size="small"
            severity="secondary"
            label="Exportar a excel"
            @click="exportarExcel"
            style="height: 30px"
          />
        </div>
      </div>

      <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 p-4 gap-5">
        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <Filters />
        </div>

        <!-- <pre>{{ JSON.stringify(getterFiltersSolicitudPermisos, null, '\t') }}</pre> -->

        <div class="col-span-1 sm:col-span-12 md:col-span-12">
          <DataTable
            ref="dt"
            :value="listSolicitudes.data"
            v-model:selection="selectedSolicitudes"
            tableStyle="min-width: 50rem"
            class="p-datatable-sm text-xs"
            stripedRows
            dataKey="id"
            @sort="orderQuery"
            :sortField="orderField"
            :sortOrder="sortOrder"
            showGridlines
          >
            <template #empty> No hay datos para mostrar. </template>
            <template #loading> Cargando la información, por favor espere... </template>
            <Column selectionMode="multiple" style="width: 3rem" v-if="$can('pharmasan.administrativa.solicitud-permisos.check')">
            </Column>
            <Column field="id" header="Id" :sortable="true">
              <template #body="{ data }">
                <div class="flex w-full justify-center items-center">
                  <span
                    class="text-blue-600 font-bold underline cursor-pointer mr-3"
                    @click="verSolicitud(data)"
                    v-tippy="{ content: `Ver solicitud` }"
                  >
                    {{ data.id }}
                  </span>
                  <i
                    v-if="data.chequeadores.length > 0"
                    class="pi pi-check-circle text-green-500 cursor-pointer"
                    v-tippy="{ content: `
                      <div class='flex flex-col'>
                        <span class='border-b border-dashed font-semibold'>Verificado por:</span>
                        <span class='text-xs mt-1'>${data.chequeadores.map(cheq => cheq.full_name).join(', ')}</span>
                      </div>
                    `, trigger: 'click' }"
                  ></i>
                  <i
                    v-else
                    class="pi pi-clock text-yellow-600 cursor-pointer"
                    v-tippy="{ content: `
                      <div class='flex flex-col'>
                        <span class=''>Sin verificar...</span>
                      </div>
                    `, trigger: 'click' }"
                  ></i>
                </div>
              </template>
            </Column>
            <Column field="createdAt" header="Fecha Solicitud" :sortable="true" style="width: 12rem">
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>Creación: {{ $h.formatDate(data.createdAt, 'YYYY-MM-DD HH:mm:ss') }}</span>
                  <span>Envio: {{ data.enviadaAt === null ? 'No se ha enviado' :$h.formatDate(data.enviadaAt, 'YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </template>
            </Column>
            <Column field="tipoTiempo" header="Tipo Tiempo" :sortable="true"></Column>
            <Column field="" header="Info fechas (AAAA-MM-DD) y tiempos (HH:MM:SS)" style="width: 15rem">
              <template #body="{data}">
                <div class="flex flex-col">
                  <div class="flex justify-between border-b border-dashed">
                    <span>Desde: {{ data.fechaInicial }}</span>
                    <span>Hasta: {{ data.fechaFinal }}</span>
                  </div>
                  <div class="flex justify-between border-b border-dashed">
                    <span>Desde: {{ data.horaInicial }}</span>
                    <span>Hasta: {{ data.horaFinal }}</span>
                  </div>
                  <div class="flex justify-between">
                    <span class="font-semibold">Tiempo total</span>
                    <span>{{ data.tiempoTotal }}</span>
                  </div>
                </div>
              </template>
            </Column>
            <Column field="nombreMotivo" header="Motivo" sortable>
              <template #body="{data}">
                <div class="flex justify-between w-full">
                  <span class="mr-2">{{ data.nombreMotivo }}</span>
                  <Icon
                    icon="uiw:message"
                    class="text-blue-500 text-sm cursor-pointer"
                    v-if="data.observacionMotivo"
                    v-tippy="{ content: `
                      <div class='flex flex-col'>
                        <span class='border-b border-dashed font-semibold'>Observación del motivo</span>
                        <span class='text-xs mt-1'>${data.observacionMotivo}</span>
                      </div>
                    `, trigger: 'click' }"
                  />
                </div>
              </template>
            </Column>
            <Column field="" header="Empleado" sortable>
              <template #body="{data}">
                <div class="flex flex-col">
                  <span>{{ data.nombre }}</span>
                  <span class="text-xs text-gray-700">CC: {{ data.cedula }}</span>
                </div>
              </template>
            </Column>
            <Column field="nombreArea" header="Área" sortable></Column>
            <Column field="nombreCargo" header="Cargo" sortable></Column>
            <Column header="Estado" :exportable="false" style="min-width:8rem" >
              <template #body="{ data }">
                <div class="flex flex-wrap justify-center" v-if="shouldShowApprovalButtons(data)">
                  <button
                    type="button"
                    style="width: 30px; height: 30px; padding: 0.35rem"
                    v-tippy="{ content: `Aprobar` }"
                    @click="aprobarSolicitud(data, 'APROBAR')"
                    class="mr-2 text-green-700 border border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-full text-sm text-center inline-flex items-center"
                  >
                    <Icon icon="heroicons:check" class="text-lg"/>
                  </button>
                  <button
                    type="button"
                    style="width: 30px; height: 30px; padding: 0.35rem"
                    v-tippy="{ content: `Rechazar` }"
                    @click="aprobarSolicitud(data, 'RECHAZAR')"
                    class="mr-2 text-red-700 border border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm text-center inline-flex items-center"
                  >
                    <Icon icon="heroicons:x-mark" class="text-lg"/>
                  </button>
                  <button
                    type="button"
                    style="width: 30px; height: 30px; padding: 0.35rem"
                    v-tippy="{ content: `Cancelar` }"
                    @click="aprobarSolicitud(data, 'CANCELAR')"
                    class="text-yellow-700 border border-yellow-700 hover:bg-yellow-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-full text-sm text-center inline-flex items-center"
                  >
                    <Icon icon="mdi:cancel" class="text-lg"/>
                  </button>
                </div>
                <div class="flex flex-col flex-wrap justify-center" v-else-if="shouldShowStatusInfo(data)">
                  <div v-if="shouldShowStatusOnly(data)" class="">
                    <button
                      class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                      :class="getStatusInfo(data).class"
                      @click="toggleAprobadores($event,data)"
                    >
                      {{ getStatusInfo(data).text }}
                    </button>
                    <span
                      class="block cursor-pointer text-center w-full underline font-semibold text-blue-800"
                      style="font-size: 0.6rem"
                      @click="toggleAprobadores($event,data)"
                    >
                      Más información
                    </span>
                    <OverlayPanel
                      ref="op"
                      :pt="{
                      content: {
                        style: 'padding: 1rem'
                      },
                    }"
                    >
                      <DataTable
                        :value="infoAprobadores"
                        class="p-datatable-sm text-xs"
                        showGridlines
                        dataKey="id"
                        responsiveLayout="scroll"
                      >
                        <template #empty> No hay información porque no tiene aprobadores. </template>
                        <Column
                          field=""
                          :header="`Estados y aprobaciones solicitud #${solicitudIdToggle}`"
                        >
                          <template #body="{data}">
                            <div class="flex flex-col flex-wrap">
                              <div class="flex flex-wrap justify-between">
                                <span class="mr-1">Aprobador: </span>
                                <span class="font-semibold"> {{ data.full_name }}</span>
                              </div>
                              <div class="flex flex-wrap justify-between">
                                <span class="mr-1">Estado: </span>
                                <span :class="`text-xs font-semibold ${data.aprobado === null ? 'text-yellow-600' : data.aprobado ? 'text-green-600' : 'text-red-600'}`">{{ data.aprobado === null ? 'Pendiente' : data.aprobado ? 'Aprobado' : 'Rechazado' }}</span>
                              </div>
                              <div class="flex flex-wrap justify-between">
                                <span class="mr-1">Observaciones: </span>
                                <span class="text-xs font-semibold text-gray-700">{{ data.observaciones }}</span>
                              </div>
                            </div>
                          </template>
                        </Column>
                      </DataTable>
                    </OverlayPanel>
                  </div>
                  <div class="" v-else>
                    <button
                      class="text-xs font-medium px-2.5 py-0.5 rounded border w-full"
                      :class="getStatusInfo(data).class"
                      v-tippy="{ content: `
                        <div class='flex flex-col' v-if='${data.estadoSolicitud === 'CANCELADO'}'>
                          <span class='border-b border-dashed font-semibold'>Observación:</span>
                          <span class='text-xs mt-1'>${data.estadoSolicitud === 'CANCELADO' ? data.observacionCancelada : data.observacionCancelada !== null ? data.observacionCancelada : 'Sin observación'}</span>
                        </div>
                      ` }"
                    >
                      {{ getStatusInfo(data).text }}
                    </button>
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Acciones" :exportable="false">
              <template #body="{data}">
                <div class="flex justify-end">
                  <Button
                    icon="pi"
                    severity="secondary"
                    :class="`p-button-rounded mr-2`"
                    v-tippy="{ content: `Enviar` }"
                    @click="enviarSolicitud(data)"
                    v-if="shouldShowSend(data)"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  >
                    <Icon icon="fa:send" class="text-base"/>
                  </Button>
                  <Button
                    icon="pi"
                    severity="secondary"
                    :class="[ 'p-button-rounded', { 'mr-2': shouldShowEditAndDelete(data) || shouldShowEdit() } ]"
                    v-tippy="{ content: `Descargar PDF` }"
                    @click="descargarPdf(data)"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  >
                    <Icon icon="teenyicons:pdf-solid" class="text-lg"/>
                  </Button>
                  <!-- <Button
                    icon="pi"
                    severity="secondary"
                    :class="[ 'p-button-rounded', { 'mr-2': shouldShowEditAndDelete(data) && shouldShowRestore(data) } ]"
                    v-tippy="{ content: `Restaurar` }"
                    @click="restaurarSolicitud(data)"
                    v-if="shouldShowRestore(data)"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  >
                    <Icon icon="heroicons:arrow-path" class="text-lg"/>
                  </Button>
                  <Button
                    icon="pi pi-file"
                    severity="secondary"
                    :class="`p-button-rounded mr-2`"
                    v-tippy="{ content: `Soportes` }"
                    @click="verSoportes(data)"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  /> -->
                  <Button
                    icon="pi pi-pencil"
                    severity="secondary"
                    :class="`p-button-rounded mr-2`"
                    v-tippy="{ content: `Editar` }"
                    @click="editarSolicitud(data)"
                    v-if="shouldShowEditAndDelete(data) || shouldShowEdit()"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  />
                  <Button
                    icon="pi pi-trash"
                    severity="secondary"
                    :class="`p-button-rounded`"
                    v-tippy="{ content: `Eliminar` }"
                    @click="eliminarSolicitud(data)"
                    v-if="shouldShowEditAndDelete(data)"
                    :pt="{
                      root: { style: 'width: 30px; height: 30px'},
                    }"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
          <Paginator
            v-model:first="offset"
            :rows="limit"
            :totalRecords="parseInt(listSolicitudes.total)"
            :rowsPerPageOptions="[5,10,20,30,100, listSolicitudes.total]"
            template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
            @page="onPage($event)"
            :pt="{
              rowPerPageDropdown: {
                input: {
                  style: 'padding: 5px'
                },
              },
            }"
          />
        </div>
      </div>
    </div>
  </div>

  <ModalSoportes ref="openModalSoportes" />
  <ModalAprobacion ref="openModalAprobacion" />
  <SidebarSolicitud ref="openSidebarSolicitud" />
</template>

<style>
.swal2-textarea {
  font-size: 1rem !important;
}
</style>
