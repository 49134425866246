<script setup>
import { computed, defineExpose, onMounted, ref } from 'vue'
import Icon from '@/components/Icon/index.vue'
import SoporteService from '../../services/soporte.service'
import Swal from 'sweetalert2'
import { error, warning } from '../../../../../../libs/mensajes'
import SolicitudService from '../../services/solicitud.service'
import { useFiltersSolicitudPermisosStore } from '../../stores/filtersSolicitudPermisos'
import { useStore } from 'vuex'

const store = useStore()
const filtersSolicitudPermisosStore = useFiltersSolicitudPermisosStore()
const _SolicitudService = ref(new SolicitudService())
const _SoporteService = ref(new SoporteService())
const visible = ref(false)
const isCheck = ref(false)
const supports = ref([])
const dataSolicitud = ref({})
const sidebarPosition = ref(window.innerWidth > 1024 ? 'right' : 'full')
const sidebarClass = ref(window.innerWidth > 1024 ? 'p-sidebar-md' : '')
const sidebarStyle = ref(window.innerWidth > 1024 ? 'width: 50vw' : '')
const visibleInfoEmpleado = ref(true)
const visibleInfoPermiso = ref(true)
const visibleInfoSoportes = ref(true)
const visibleInfoAprobadores = ref(true)
const visibleInfoChequeadores = ref(true)

// Abrir el sidebar
const openSidebar = async (data) => {
  visible.value = true
  dataSolicitud.value = data
  await fetchSupportsBySolicitud(data.id)

  isCheck.value = !!validarChequeo()
}

// Obtener la información del usuario
const getUser = computed(() => {
  return store.getters['auth/user']
})

// Funcion para validar si el usuario logueado ya dio visto bueno a la solicitud
const validarChequeo = () => {
  return dataSolicitud.value.chequeadores.some(objeto => objeto.chequeadorId === getUser.value.base)
}

// Funcion para obtener los soportes de la solicitud
const fetchSupportsBySolicitud = async (solicitudId) => {
  await _SoporteService.value.listarSoportesPorSolicitud(solicitudId).then(({ status, data: response }) => {
    if (status !== 200) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Problemas al listar los soportes de la solicitud, consulte con el administrador del sistema'
      })
    } else {
      supports.value = response
    }
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

/* Visualizar o descargar soportes */
const verSoporte = async (adjunto) => {
  await _SoporteService.value.descargarSoporte(adjunto).then((url) => {
    if (adjunto !== null) {
      window.open(url, '_blank')
    } else {
      warning('No hay soporte')
    }
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

// Computada del estado de la solicitud para cambiar el color del icono
const colorEstado = computed(() => {
  switch (dataSolicitud.value.estadoSolicitud) {
    case 'APROBADO':
      return {
        colorText: 'text-green-600',
        colorClass: 'bg-green-100 text-green-800'
      }
    case 'RECHAZADO':
      return {
        colorText: 'text-red-600',
        colorClass: 'bg-red-100 text-red-800'
      }
    case 'CANCELADO':
      return {
        colorText: 'text-pink-600',
        colorClass: 'bg-pink-100 text-pink-800'
      }
    case 'SIN ENVIAR':
      return {
        colorText: 'text-blue-600',
        colorClass: 'bg-blue-100 text-blue-800'
      }
    case 'SIN APROBADOR':
      return {
        colorText: 'text-gray-600',
        colorClass: 'bg-gray-100 text-gray-800'
      }
    default:
      return {
        colorText: 'text-yellow-600',
        colorClass: 'bg-yellow-100 text-yellow-800'
      }
  }
})

// Descontable
const descontable = computed(() => {
  return dataSolicitud.value.descontable === null ? 'Por gestionar' : dataSolicitud.value.descontable === true ? 'SI' : 'NO'
})

// Funcion para dar el visto bueno a la solicitud
const submitCheck = async () => {
  Swal.fire({
    icon: 'info',
    title: 'Información',
    html: `¿Esta seguro de verificar la solicitud de permiso # ${dataSolicitud.value.id}?`,
    showDenyButton: true,
    denyButtonText: 'No',
    confirmButtonText: 'Si',
    confirmButtonColor: '#16A34A',
    input: 'textarea',
    inputPlaceholder: 'Si desea puede ingresar las observaciones de la verificación (opcional)',
    inputAttributes: {
      'aria-label': 'Si desea puede ingresar las observaciones de la verificación (opcional)'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      const data = {
        solicitudPermisoId: dataSolicitud.value.id,
        observaciones: result.value
      }

      _SolicitudService.value.crearChequeo(data).then(({ status, data: response }) => {
        if (status !== 200) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Problemas al dar la verificación a la solicitud, consulte con el administrador del sistema'
          })
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'Se ha verificado la solicitud correctamente'
          }).then(() => {
            visible.value = false
            filtersSolicitudPermisosStore.setRefrescarListadoSolicitudes(true)
          })
        }
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }
  })
}

// Funcion para ocultar o mostrar la información del empleado
const onVisibleInfoEmpleado = () => {
  visibleInfoEmpleado.value = !visibleInfoEmpleado.value
}

// Funcion para ocultar o mostrar la información del permiso
const onVisibleInfoPermiso = () => {
  visibleInfoPermiso.value = !visibleInfoPermiso.value
}

// Funcion para ocultar o mostrar la información de los soportes
const onVisibleInfoSoportes = () => {
  visibleInfoSoportes.value = !visibleInfoSoportes.value
}

// Funcion para ocultar o mostrar la información de los aprobadores
const onVisibleInfoAprobadores = () => {
  visibleInfoAprobadores.value = !visibleInfoAprobadores.value
}

// Funcion para ocultar o mostrar la información de los chequeadores
const onVisibleInfoChequeadores = () => {
  visibleInfoChequeadores.value = !visibleInfoChequeadores.value
}

//
window.addEventListener('resize', evt => {
  sidebarPosition.value = window.innerWidth > 1024 ? 'right' : 'full'
  sidebarClass.value = window.innerWidth > 1024 ? 'p-sidebar-md' : ''
  sidebarStyle.value = window.innerWidth > 1024 ? 'width: 50vw' : ''
})

// Se expone la funcion para abrir el sidebar, para que pueda ser llamado desde el componente padre
defineExpose({
  openSidebar
})

// Onmounted
onMounted(() => {
  console.log('onMounted, sidebar...')
})
</script>

<template>
  <div class="card flex justify-content-center">
    <Sidebar
      v-model:visible="visible"
      :position="sidebarPosition"
      :baseZIndex="1000"
      :class="sidebarClass"
      :style="sidebarStyle"
      :pt="{
        header: {
          style: 'display: flex; align-items: center; justify-content: space-between;'
        }
      }"
    >
      <!-- <p :class="`water-maker ${colorEstado.colorText}`">{{ dataSolicitud.estadoSolicitud }}</p> -->
      <template #header>
        <Button
          v-if="$can('pharmasan.administrativa.solicitud-permisos.check') && !isCheck"
          label="Verificar"
          size="small"
          severity="secondary"
          icon="pi pi-check-circle"
          v-tippy="{ content: 'Agregar visto bueno a la solicitud' }"
          @click="submitCheck"
        />
        <Button
          v-if="$can('pharmasan.administrativa.solicitud-permisos.check') && isCheck"
          label="Verificado"
          size="small"
          severity="success"
          icon="pi pi-check-circle"
          v-tippy="{ content: 'Ya ha verificado esta solicitud' }"
          disabled
        />
      </template>

      <div class="flex flex-col sm:flex-row items-center justify-between mb-4">
        <h2 class="font-bold text-gray-700 text-xl">Solicitud de Permiso # {{ dataSolicitud.id }}</h2>
        <span :class="`${colorEstado.colorClass} text-sm font-medium mr-2 px-2.5 py-0.5 rounded-full`">{{ dataSolicitud.estadoSolicitud }}</span>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoEmpleado">Información del Empleado</span>
          </Divider>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2" v-show="visibleInfoEmpleado">
        <div class="col-span-12 sm:col-span-6 flex">
          <div class="symbol symbol-50 symbol-light-danger mr-5">
          <span class="symbol-label">
            <Icon
              icon="clarity:employee-solid"
              class="text-2xl"
            />
          </span>
          </div>
          <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
            <span class="text-gray-500 text-xs">Nombre:</span>
            <span class="text-gray-700 text-sm">{{ dataSolicitud.nombre }}</span>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 flex">
          <div class="symbol symbol-50 symbol-light-primary mr-5">
          <span class="symbol-label">
            <Icon
              icon="pepicons-print:identification"
              class="text-3xl"
            />
          </span>
          </div>
          <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
            <span class="text-gray-500 text-xs">Cedula:</span>
            <span class="text-gray-700 text-sm">{{ dataSolicitud.cedula }}</span>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 flex">
          <div class="symbol symbol-50 symbol-light-dark mr-5">
          <span class="symbol-label">
            <Icon
              icon="mdi:location"
              class="text-3xl"
            />
          </span>
          </div>
          <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
            <span class="text-gray-500 text-xs">Área:</span>
            <span class="text-gray-700 text-sm">{{ dataSolicitud.nombreArea }}</span>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 flex">
          <div class="symbol symbol-50 symbol-light-warning mr-5">
          <span class="symbol-label">
            <Icon
              icon="eos-icons:job"
              class="text-3xl"
            />
          </span>
          </div>
          <div class="flex flex-col flex-grow p-my-lg-0 my-2 pr-3 justify-center">
            <span class="text-gray-500 text-xs">Cargo:</span>
            <span class="text-gray-700 text-sm">{{dataSolicitud.nombreCargo }}</span>
          </div>
        </div>

        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Fecha Solicitud:</span>
            <span class="text-gray-700 text-sm">{{ dataSolicitud.fechaSolicitud }}</span>
          </div>
        </div>

        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Tipo Empleado:</span>
            <span class="text-gray-700 text-sm">{{ dataSolicitud.tipoEmpleado }}</span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoPermiso">Información del Permiso</span>
          </Divider>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2" v-show="visibleInfoPermiso">
        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Motivo:</span>
            <span class="text-gray-700 text-sm">{{dataSolicitud.nombreMotivo }}</span>
          </div>
        </div>

        <div class="col-span-12 flex">
          <div class="flex flex-col sm:flex-row flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Observación del Motivo:</span>
            <span class="text-gray-700 text-sm">{{dataSolicitud.observacionMotivo }}</span>
          </div>
        </div>

        <div class="col-span-6 flex">
          <div class="flex flex-col flex-grow p-my-lg-0 pr-3 justify-center p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs text-center pb-2 border-b border-dashed">Fecha:</span>
            <div class="flex flex-wrap justify-between mt-2">
              <div class="flex flex-wrap items-center">
                <span class="text-gray-500 text-xs mr-2">Desde: </span>
                <span class="text-gray-700 text-sm">{{dataSolicitud.fechaInicial }}</span>
              </div>
              <div class="flex flex-wrap items-center">
                <span class="text-gray-500 text-xs mr-2">Hasta: </span>
                <span class="text-gray-700 text-sm">{{dataSolicitud.fechaFinal }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-6 flex">
          <div class="flex flex-col flex-grow p-my-lg-0 pr-3 justify-center p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs text-center pb-2 border-b border-dashed">Hora:</span>
            <div class="flex flex-wrap justify-between mt-2">
              <div class="flex flex-wrap items-center">
                <span class="text-gray-500 text-xs mr-2">A partir de: </span>
                <span class="text-gray-700 text-sm">{{dataSolicitud.horaInicial }}</span>
              </div>
              <div class="flex flex-wrap items-center">
                <span class="text-gray-500 text-xs mr-2">Hasta las: </span>
                <span class="text-gray-700 text-sm">{{dataSolicitud.horaFinal }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Tipo Tiempo:</span>
            <span class="text-gray-700 text-sm">{{dataSolicitud.tipoTiempo }}</span>
          </div>
        </div>

        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <span class="text-gray-500 text-xs">Descontable:</span>
            <span class="text-gray-700 text-sm">{{ descontable }}</span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">
          <Divider
              align="left"
              type="dashed"
              :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoSoportes">Soportes</span>
          </Divider>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2" v-show="visibleInfoSoportes">
        <div class="col-span-12 flex">
          <div class="flex flex-grow p-my-lg-0 pr-3 justify-between p-2 rounded-lg bg-gray-50">
            <div v-if="supports.length <= 0" class="flex flex-col justify-center items-center w-full">
              <svg class="opacity-20" height="100px" width="100px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#FFFFFF;" d="M494.012,369.545c9.199,72.482-51.985,133.675-124.467,124.467
c-49.399-6.272-89.293-46.165-95.565-95.565c-9.19-72.474,51.994-133.658,124.476-124.459
C447.855,280.26,487.74,320.145,494.012,369.545z"/>
                <path style="fill:#F0F0F0;" d="M401.553,274.441c-31.275-4.873-60.459,3.567-83.166,20.309c19.115-5.666,40.286-6.537,62.31-0.222
c40.038,11.486,70.963,45.261,78.285,86.263c7.987,44.74-11.11,85.658-43.503,109.534c49.681-14.729,84.932-63.309,78.754-118.946
C488.789,322.398,450.244,282.027,401.553,274.441z"/>
                <path d="M264.533,477.867H25.6c-3.866,0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.814-8.533-8.533-8.533S0,439.023,0,443.733v25.6
c0,13.636,11.964,25.6,25.6,25.6h238.933c4.719,0,8.533-3.823,8.533-8.533S269.252,477.867,264.533,477.867z"/>
                <path d="M8.533,384c4.719,0,8.533-3.823,8.533-8.533v-230.4c0-4.702,3.823-8.533,8.533-8.533h443.733
c4.71,0,8.533,3.831,8.533,8.533v119.467c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533V145.067
c0-14.114-11.486-25.6-25.6-25.6V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692c-1.843,0-3.661-0.606-5.129-1.707L197.402,5.12
C193.007,1.818,187.554,0,182.042,0H51.2C37.086,0,25.6,11.486,25.6,25.6v93.867c-14.114,0-25.6,11.486-25.6,25.6v230.4
C0,380.177,3.814,384,8.533,384z M409.6,119.467H85.333v-8.533c0-4.702,3.823-8.533,8.533-8.533h307.2
c4.71,0,8.533,3.831,8.533,8.533V119.467z M42.667,25.6c0-4.702,3.823-8.533,8.533-8.533h130.842c1.843,0,3.661,0.606,5.129,1.707
l59.162,44.373c4.395,3.302,9.847,5.12,15.36,5.12h182.042c4.71,0,8.533,3.831,8.533,8.533v42.667h-25.6v-8.533
c0-14.114-11.486-25.6-25.6-25.6h-307.2c-14.114,0-25.6,11.486-25.6,25.6v8.533h-25.6V25.6z"/>
                <path d="M384,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S454.579,256,384,256z M273.067,384
c0-61.167,49.766-110.933,110.933-110.933c27.52,0,52.676,10.129,72.081,26.786L299.853,456.081
C283.196,436.676,273.067,411.52,273.067,384z M384,494.933c-27.52,0-52.676-10.129-72.081-26.786L468.156,311.91
c16.649,19.413,26.778,44.57,26.778,72.09C494.933,445.167,445.167,494.933,384,494.933z"/>
                <path d="M102.4,59.733c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533H68.267c-4.719,0-8.533,3.823-8.533,8.533
s3.814,8.533,8.533,8.533H102.4z"/>
                <path d="M170.667,59.733h8.533c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.719,0-8.533,3.823-8.533,8.533
S165.948,59.733,170.667,59.733z"/>
                <circle cx="136.533" cy="51.2" r="8.533"/>
                <circle cx="8.533" cy="409.6" r="8.533"/>
                <path d="M128,324.267c4.71,0,8.533-3.823,8.533-8.533c0-46.473,22.588-70.221,30.976-78.635c3.738,1.075,7.612,1.835,11.691,1.835
c4.079,0,7.953-0.759,11.691-1.835c8.388,8.414,30.976,32.162,30.976,78.635c0,4.71,3.823,8.533,8.533,8.533
s8.533-3.823,8.533-8.533c0-47.787-21.018-74.863-32.375-86.997c9.276-7.834,15.309-19.405,15.309-32.469
c0-23.526-19.14-42.667-42.667-42.667s-42.667,19.14-42.667,42.667c0,13.065,6.033,24.636,15.309,32.469
c-11.358,12.134-32.375,39.211-32.375,86.997C119.467,320.444,123.29,324.267,128,324.267z M179.2,170.667
c14.114,0,25.6,11.486,25.6,25.6c0,14.114-11.486,25.6-25.6,25.6c-14.114,0-25.6-11.486-25.6-25.6
C153.6,182.153,165.086,170.667,179.2,170.667z"/>
                <path d="M281.6,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533
c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
c5.265-6.007,8.567-13.773,8.567-22.374C315.733,177.442,300.425,162.133,281.6,162.133z M281.6,213.333
c-9.412,0-17.067-7.654-17.067-17.067S272.188,179.2,281.6,179.2c9.412,0,17.067,7.654,17.067,17.067S291.012,213.333,281.6,213.333
z"/>
                <path d="M76.8,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-7.68,0.043-13.278,5.683-19.166
c3.575,1.28,7.373,2.099,11.383,2.099c4.011,0,7.808-0.819,11.383-2.099c5.641,5.888,5.683,11.486,5.683,19.166
c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
c5.265-6.007,8.567-13.773,8.567-22.374C110.933,177.442,95.625,162.133,76.8,162.133z M76.8,213.333
c-9.412,0-17.067-7.654-17.067-17.067S67.388,179.2,76.8,179.2s17.067,7.654,17.067,17.067S86.212,213.333,76.8,213.333z"/>
                <g>
<path style="fill:#CC004E;" d="M300.629,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133s7.851-0.853,11.349-2.133c5.717,5.888,5.717,11.52,5.717,19.2
C298.667,249.515,299.435,251.477,300.629,252.928z"/>
                  <circle style="fill:#CC004E;" cx="281.6" cy="196.267" r="17.067"/>
</g>
                <g>
<path style="fill:#0F7BE4;" d="M229.12,324.181c-11.008,34.219-88.832,34.219-99.84,0c4.096-0.683,7.253-4.181,7.253-8.448
c0-46.507,22.613-70.229,30.976-78.677c3.755,1.109,7.595,1.877,11.691,1.877s7.936-0.768,11.691-1.877
c8.363,8.448,30.976,32.171,30.976,78.677C221.867,320,225.024,323.499,229.12,324.181z"/>
                  <circle style="fill:#0F7BE4;" cx="179.2" cy="196.267" r="25.6"/>
</g>
                <path style="fill:#FDB50D;" d="M409.6,110.933v8.533H85.333v-8.533c0-4.693,3.84-8.533,8.533-8.533h307.2
C405.76,102.4,409.6,106.24,409.6,110.933z"/>
                <path style="fill:#AFAFAF;" d="M477.867,264.533V145.067c0-4.693-3.84-8.533-8.533-8.533H25.6c-4.693,0-8.533,3.84-8.533,8.533
v230.4c0,4.693-3.84,8.533-8.533,8.533v17.067c4.693,0,8.533,3.84,8.533,8.533c0,4.693-3.84,8.533-8.533,8.533V435.2
c4.693,0,8.533,3.84,8.533,8.533v25.6c0,4.693,4.693,8.533,8.533,8.533h238.933c4.693,0,8.533,3.84,8.533,8.533h34.219
C276.139,463.019,256,425.813,256,384c0-70.571,57.429-128,128-128c41.813,0,79.019,20.139,102.4,51.285v-34.219
C481.707,273.067,477.867,269.227,477.867,264.533z M110.933,247.467c0,4.693-3.84,8.533-8.533,8.533
c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C56.491,254.635,54.016,256,51.2,256
c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
c0-18.859,15.275-34.133,34.133-34.133s34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
C110.848,228.779,110.933,239.104,110.933,247.467z M230.4,324.267c-0.427,0-0.853,0-1.28-0.085
c-11.008,34.219-88.832,34.219-99.84,0c-0.427,0.085-0.853,0.085-1.28,0.085c-4.693,0-8.533-3.84-8.533-8.533
c0-47.787,20.992-74.837,32.341-87.04c-9.216-7.765-15.275-19.371-15.275-32.427c0-23.552,19.115-42.667,42.667-42.667
s42.667,19.115,42.667,42.667c0,13.056-6.059,24.661-15.275,32.427c11.349,12.203,32.341,39.253,32.341,87.04
C238.933,320.427,235.093,324.267,230.4,324.267z M315.733,247.467c0,4.693-3.84,8.533-8.533,8.533
c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C261.291,254.635,258.816,256,256,256
c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
c0-18.859,15.275-34.133,34.133-34.133c18.859,0,34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
C315.648,228.779,315.733,239.104,315.733,247.467z"/>
                <g>
<circle style="fill:#CC004E;" cx="76.8" cy="196.267" r="17.067"/>
                  <path style="fill:#CC004E;" d="M95.829,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133c4.011,0,7.851-0.853,11.349-2.133
c5.717,5.888,5.717,11.52,5.717,19.2C93.867,249.515,94.635,251.477,95.829,252.928z"/>
</g>
                <path style="fill:#7E939E;" d="M477.867,264.448V144.981c0-4.693-3.84-8.533-8.533-8.533h-25.6v111.019
c-12.117-5.12-25.344-8.107-39.168-8.533c-1.195-0.085-2.304-0.085-3.499-0.085c-15.104,0-29.525,3.072-42.667,8.533h-0.085
c-13.653,5.717-25.941,14.08-36.096,24.491c5.035-2.816,10.325-5.291,15.701-7.339c14.251-5.547,29.781-8.619,46.08-8.619
c21.589,0,41.899,5.376,59.733,14.848c16.811,8.789,31.317,21.333,42.667,36.437v-34.219
C481.707,272.981,477.867,269.141,477.867,264.448z"/>
                <path style="fill:#F6981E;" d="M401.067,102.4h-34.133c4.693,0,8.533,3.84,8.533,8.533v8.533H409.6v-8.533
C409.6,106.24,405.76,102.4,401.067,102.4z"/>
                <g>
<path style="fill:#215EFF;" d="M179.2,170.667c-0.137,0-0.273,0.043-0.41,0.043c10.103,3.43,17.476,12.919,17.476,24.149
c0,13.935-11.298,25.327-25.19,25.557c2.569,0.87,5.274,1.451,8.124,1.451c14.08,0,25.6-11.52,25.6-25.6
S193.28,170.667,179.2,170.667z"/>
                  <path style="fill:#215EFF;" d="M215.014,325.794c0,4.745,3.14,8.619,7.228,9.463c3.132-3.174,5.521-6.861,6.878-11.076
c-4.096-0.683-7.253-4.181-7.253-8.448c0-46.507-22.613-70.229-30.976-78.677c-2.022,0.597-4.079,1.067-6.178,1.399
C193.997,249.02,215.014,275.635,215.014,325.794z"/>
</g>
                <g>
<path style="fill:#9B0045;" d="M291.337,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
C291.635,234.854,291.337,241.041,291.337,247.467z"/>
                  <path style="fill:#9B0045;" d="M282.223,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
C298.667,187.093,291.319,179.601,282.223,179.26z"/>
                  <path style="fill:#9B0045;" d="M86.537,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
C86.835,234.854,86.537,241.041,86.537,247.467z"/>
                  <path style="fill:#9B0045;" d="M77.423,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
C93.867,187.093,86.519,179.601,77.423,179.26z"/>
</g>
                <path style="fill:#7E939E;" d="M443.733,68.267H261.717c-5.547,0-11.008-1.792-15.36-5.12l-59.221-44.373
c-1.451-1.109-3.243-1.707-5.12-1.707H51.2c-4.693,0-8.533,3.84-8.533,8.533v93.867h25.6v-8.533c0-14.08,11.52-25.6,25.6-25.6h307.2
c14.08,0,25.6,11.52,25.6,25.6v8.533h25.6V76.8C452.267,72.107,448.427,68.267,443.733,68.267z M102.4,59.733H68.267
c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533H102.4c4.693,0,8.533,3.84,8.533,8.533S107.093,59.733,102.4,59.733z
 M136.533,59.733c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533s8.533,3.84,8.533,8.533S141.227,59.733,136.533,59.733z
 M179.2,59.733h-8.533c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533h8.533c4.693,0,8.533,3.84,8.533,8.533
S183.893,59.733,179.2,59.733z"/>
</svg>
              <span class="text-red-900 opacity-20 font-bold">No hay soportes agregados</span>
            </div>
            <div v-else class="w-full">
              <div v-for="(support, index) in supports" :key="index" class="flex flex-wrap justify-between items-center p-1 w-full">
                <div class="flex flex-wrap items-center">
                  <Icon
                    icon="iwwa:file-pdf"
                    class="text-2xl font-bold text-red-600 mr-4"
                  />
                  <div class="flex flex-col">
                    <span class="text-xs text-gray-500">Soporte {{ support.id }}</span>
                    <span class="text-xs text-gray-500">Descripción: {{ support.descripcion }}</span>
                  </div>
                </div>
                <Icon
                  icon="mdi:eye"
                  class="text-2xl font-bold text-blue-600 cursor-pointer"
                  @click="verSoporte(support.soporte)"
                  v-tippy="{ content: 'Ver soporte' }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoAprobadores">Aprobadores</span>
          </Divider>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2" v-show="visibleInfoAprobadores">
        <div class="col-span-12 flex">
          <div v-if="dataSolicitud.aprobadores.length <= 0" class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-grow flex-col p-my-lg-0 pr-3 items-center justify-between p-2 rounded-lg bg-gray-50 w-full">
              <svg class="opacity-20" height="100px" width="100px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 512 512" xml:space="preserve">
    <path style="fill:#FFFFFF;" d="M494.012,369.545c9.199,72.482-51.985,133.675-124.467,124.467
    c-49.399-6.272-89.293-46.165-95.565-95.565c-9.19-72.474,51.994-133.658,124.476-124.459
    C447.855,280.26,487.74,320.145,494.012,369.545z"/>
                <path style="fill:#F0F0F0;" d="M401.553,274.441c-31.275-4.873-60.459,3.567-83.166,20.309c19.115-5.666,40.286-6.537,62.31-0.222
    c40.038,11.486,70.963,45.261,78.285,86.263c7.987,44.74-11.11,85.658-43.503,109.534c49.681-14.729,84.932-63.309,78.754-118.946
    C488.789,322.398,450.244,282.027,401.553,274.441z"/>
                <path d="M264.533,477.867H25.6c-3.866,0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.814-8.533-8.533-8.533S0,439.023,0,443.733v25.6
    c0,13.636,11.964,25.6,25.6,25.6h238.933c4.719,0,8.533-3.823,8.533-8.533S269.252,477.867,264.533,477.867z"/>
                <path d="M8.533,384c4.719,0,8.533-3.823,8.533-8.533v-230.4c0-4.702,3.823-8.533,8.533-8.533h443.733
    c4.71,0,8.533,3.831,8.533,8.533v119.467c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533V145.067
    c0-14.114-11.486-25.6-25.6-25.6V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692c-1.843,0-3.661-0.606-5.129-1.707L197.402,5.12
    C193.007,1.818,187.554,0,182.042,0H51.2C37.086,0,25.6,11.486,25.6,25.6v93.867c-14.114,0-25.6,11.486-25.6,25.6v230.4
    C0,380.177,3.814,384,8.533,384z M409.6,119.467H85.333v-8.533c0-4.702,3.823-8.533,8.533-8.533h307.2
    c4.71,0,8.533,3.831,8.533,8.533V119.467z M42.667,25.6c0-4.702,3.823-8.533,8.533-8.533h130.842c1.843,0,3.661,0.606,5.129,1.707
    l59.162,44.373c4.395,3.302,9.847,5.12,15.36,5.12h182.042c4.71,0,8.533,3.831,8.533,8.533v42.667h-25.6v-8.533
    c0-14.114-11.486-25.6-25.6-25.6h-307.2c-14.114,0-25.6,11.486-25.6,25.6v8.533h-25.6V25.6z"/>
                <path d="M384,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S454.579,256,384,256z M273.067,384
    c0-61.167,49.766-110.933,110.933-110.933c27.52,0,52.676,10.129,72.081,26.786L299.853,456.081
    C283.196,436.676,273.067,411.52,273.067,384z M384,494.933c-27.52,0-52.676-10.129-72.081-26.786L468.156,311.91
    c16.649,19.413,26.778,44.57,26.778,72.09C494.933,445.167,445.167,494.933,384,494.933z"/>
                <path d="M102.4,59.733c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533H68.267c-4.719,0-8.533,3.823-8.533,8.533
    s3.814,8.533,8.533,8.533H102.4z"/>
                <path d="M170.667,59.733h8.533c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.719,0-8.533,3.823-8.533,8.533
    S165.948,59.733,170.667,59.733z"/>
                <circle cx="136.533" cy="51.2" r="8.533"/>
                <circle cx="8.533" cy="409.6" r="8.533"/>
                <path d="M128,324.267c4.71,0,8.533-3.823,8.533-8.533c0-46.473,22.588-70.221,30.976-78.635c3.738,1.075,7.612,1.835,11.691,1.835
    c4.079,0,7.953-0.759,11.691-1.835c8.388,8.414,30.976,32.162,30.976,78.635c0,4.71,3.823,8.533,8.533,8.533
    s8.533-3.823,8.533-8.533c0-47.787-21.018-74.863-32.375-86.997c9.276-7.834,15.309-19.405,15.309-32.469
    c0-23.526-19.14-42.667-42.667-42.667s-42.667,19.14-42.667,42.667c0,13.065,6.033,24.636,15.309,32.469
    c-11.358,12.134-32.375,39.211-32.375,86.997C119.467,320.444,123.29,324.267,128,324.267z M179.2,170.667
    c14.114,0,25.6,11.486,25.6,25.6c0,14.114-11.486,25.6-25.6,25.6c-14.114,0-25.6-11.486-25.6-25.6
    C153.6,182.153,165.086,170.667,179.2,170.667z"/>
                <path d="M281.6,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
    c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533
    c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
    c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
    c5.265-6.007,8.567-13.773,8.567-22.374C315.733,177.442,300.425,162.133,281.6,162.133z M281.6,213.333
    c-9.412,0-17.067-7.654-17.067-17.067S272.188,179.2,281.6,179.2c9.412,0,17.067,7.654,17.067,17.067S291.012,213.333,281.6,213.333
    z"/>
                <path d="M76.8,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
    c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-7.68,0.043-13.278,5.683-19.166
    c3.575,1.28,7.373,2.099,11.383,2.099c4.011,0,7.808-0.819,11.383-2.099c5.641,5.888,5.683,11.486,5.683,19.166
    c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
    c5.265-6.007,8.567-13.773,8.567-22.374C110.933,177.442,95.625,162.133,76.8,162.133z M76.8,213.333
    c-9.412,0-17.067-7.654-17.067-17.067S67.388,179.2,76.8,179.2s17.067,7.654,17.067,17.067S86.212,213.333,76.8,213.333z"/>
                <g>
    <path style="fill:#CC004E;" d="M300.629,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
    c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133s7.851-0.853,11.349-2.133c5.717,5.888,5.717,11.52,5.717,19.2
    C298.667,249.515,299.435,251.477,300.629,252.928z"/>
                  <circle style="fill:#CC004E;" cx="281.6" cy="196.267" r="17.067"/>
    </g>
                <g>
    <path style="fill:#0F7BE4;" d="M229.12,324.181c-11.008,34.219-88.832,34.219-99.84,0c4.096-0.683,7.253-4.181,7.253-8.448
    c0-46.507,22.613-70.229,30.976-78.677c3.755,1.109,7.595,1.877,11.691,1.877s7.936-0.768,11.691-1.877
    c8.363,8.448,30.976,32.171,30.976,78.677C221.867,320,225.024,323.499,229.12,324.181z"/>
                  <circle style="fill:#0F7BE4;" cx="179.2" cy="196.267" r="25.6"/>
    </g>
                <path style="fill:#FDB50D;" d="M409.6,110.933v8.533H85.333v-8.533c0-4.693,3.84-8.533,8.533-8.533h307.2
    C405.76,102.4,409.6,106.24,409.6,110.933z"/>
                <path style="fill:#AFAFAF;" d="M477.867,264.533V145.067c0-4.693-3.84-8.533-8.533-8.533H25.6c-4.693,0-8.533,3.84-8.533,8.533
    v230.4c0,4.693-3.84,8.533-8.533,8.533v17.067c4.693,0,8.533,3.84,8.533,8.533c0,4.693-3.84,8.533-8.533,8.533V435.2
    c4.693,0,8.533,3.84,8.533,8.533v25.6c0,4.693,4.693,8.533,8.533,8.533h238.933c4.693,0,8.533,3.84,8.533,8.533h34.219
    C276.139,463.019,256,425.813,256,384c0-70.571,57.429-128,128-128c41.813,0,79.019,20.139,102.4,51.285v-34.219
    C481.707,273.067,477.867,269.227,477.867,264.533z M110.933,247.467c0,4.693-3.84,8.533-8.533,8.533
    c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C56.491,254.635,54.016,256,51.2,256
    c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
    c0-18.859,15.275-34.133,34.133-34.133s34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
    C110.848,228.779,110.933,239.104,110.933,247.467z M230.4,324.267c-0.427,0-0.853,0-1.28-0.085
    c-11.008,34.219-88.832,34.219-99.84,0c-0.427,0.085-0.853,0.085-1.28,0.085c-4.693,0-8.533-3.84-8.533-8.533
    c0-47.787,20.992-74.837,32.341-87.04c-9.216-7.765-15.275-19.371-15.275-32.427c0-23.552,19.115-42.667,42.667-42.667
    s42.667,19.115,42.667,42.667c0,13.056-6.059,24.661-15.275,32.427c11.349,12.203,32.341,39.253,32.341,87.04
    C238.933,320.427,235.093,324.267,230.4,324.267z M315.733,247.467c0,4.693-3.84,8.533-8.533,8.533
    c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C261.291,254.635,258.816,256,256,256
    c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
    c0-18.859,15.275-34.133,34.133-34.133c18.859,0,34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
    C315.648,228.779,315.733,239.104,315.733,247.467z"/>
                <g>
    <circle style="fill:#CC004E;" cx="76.8" cy="196.267" r="17.067"/>
                  <path style="fill:#CC004E;" d="M95.829,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
    c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133c4.011,0,7.851-0.853,11.349-2.133
    c5.717,5.888,5.717,11.52,5.717,19.2C93.867,249.515,94.635,251.477,95.829,252.928z"/>
    </g>
                <path style="fill:#7E939E;" d="M477.867,264.448V144.981c0-4.693-3.84-8.533-8.533-8.533h-25.6v111.019
    c-12.117-5.12-25.344-8.107-39.168-8.533c-1.195-0.085-2.304-0.085-3.499-0.085c-15.104,0-29.525,3.072-42.667,8.533h-0.085
    c-13.653,5.717-25.941,14.08-36.096,24.491c5.035-2.816,10.325-5.291,15.701-7.339c14.251-5.547,29.781-8.619,46.08-8.619
    c21.589,0,41.899,5.376,59.733,14.848c16.811,8.789,31.317,21.333,42.667,36.437v-34.219
    C481.707,272.981,477.867,269.141,477.867,264.448z"/>
                <path style="fill:#F6981E;" d="M401.067,102.4h-34.133c4.693,0,8.533,3.84,8.533,8.533v8.533H409.6v-8.533
    C409.6,106.24,405.76,102.4,401.067,102.4z"/>
                <g>
    <path style="fill:#215EFF;" d="M179.2,170.667c-0.137,0-0.273,0.043-0.41,0.043c10.103,3.43,17.476,12.919,17.476,24.149
    c0,13.935-11.298,25.327-25.19,25.557c2.569,0.87,5.274,1.451,8.124,1.451c14.08,0,25.6-11.52,25.6-25.6
    S193.28,170.667,179.2,170.667z"/>
                  <path style="fill:#215EFF;" d="M215.014,325.794c0,4.745,3.14,8.619,7.228,9.463c3.132-3.174,5.521-6.861,6.878-11.076
    c-4.096-0.683-7.253-4.181-7.253-8.448c0-46.507-22.613-70.229-30.976-78.677c-2.022,0.597-4.079,1.067-6.178,1.399
    C193.997,249.02,215.014,275.635,215.014,325.794z"/>
    </g>
                <g>
    <path style="fill:#9B0045;" d="M291.337,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
    c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
    C291.635,234.854,291.337,241.041,291.337,247.467z"/>
                  <path style="fill:#9B0045;" d="M282.223,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
    c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
    C298.667,187.093,291.319,179.601,282.223,179.26z"/>
                  <path style="fill:#9B0045;" d="M86.537,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
    c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
    C86.835,234.854,86.537,241.041,86.537,247.467z"/>
                  <path style="fill:#9B0045;" d="M77.423,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
    c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
    C93.867,187.093,86.519,179.601,77.423,179.26z"/>
    </g>
                <path style="fill:#7E939E;" d="M443.733,68.267H261.717c-5.547,0-11.008-1.792-15.36-5.12l-59.221-44.373
    c-1.451-1.109-3.243-1.707-5.12-1.707H51.2c-4.693,0-8.533,3.84-8.533,8.533v93.867h25.6v-8.533c0-14.08,11.52-25.6,25.6-25.6h307.2
    c14.08,0,25.6,11.52,25.6,25.6v8.533h25.6V76.8C452.267,72.107,448.427,68.267,443.733,68.267z M102.4,59.733H68.267
    c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533H102.4c4.693,0,8.533,3.84,8.533,8.533S107.093,59.733,102.4,59.733z
     M136.533,59.733c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533s8.533,3.84,8.533,8.533S141.227,59.733,136.533,59.733z
     M179.2,59.733h-8.533c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533h8.533c4.693,0,8.533,3.84,8.533,8.533
    S183.893,59.733,179.2,59.733z"/>
    </svg>
              <span class="text-red-900 opacity-20 font-bold">No hay aprobadores configurados</span>
            </div>
          </div>
          <div v-else class="w-full">
            <div v-for="(aprobador, index) in dataSolicitud.aprobadores" :key="index">
              <div class="flex flex-col flex-grow w-full justify-center p-2 rounded-lg bg-gray-50 mb-2">
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Nombre: </span>
                  <span class="text-gray-700 text-sm">{{ aprobador.full_name }}</span>
                </div>
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Estado: </span>
                  <span
                    class="text-gray-700 text-sm"
                    :class="[
                      aprobador.aprobado === null ? 'text-yellow-500' : aprobador.aprobado === true ? 'text-green-500' : 'text-red-500'
                    ]"
                  >{{ aprobador.aprobado === null ? 'PENDIENTE' : aprobador.aprobado === true ? 'APROBADO' : 'RECHAZADO' }}</span>
                </div>
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Observación: </span>
                  <span class="text-gray-700 text-sm">{{ aprobador.observaciones }}</span>
                </div>
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Fecha: </span>
                  <span class="text-gray-700 text-sm">{{ aprobador.fechaAprobacion === null ? '' : $h.formatDate(aprobador.fechaAprobacion, 'YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-12">
          <Divider
            align="left"
            type="dashed"
            :pt="{
              root: { style: 'margin: 7px 0 7px 0 !important; padding: 0 !important' },
              content: { style: 'padding: 0 5px 0 0 !important' }
            }"
          >
            <span class="text-sm font-semibold cursor-pointer text-blue-900" @click="onVisibleInfoChequeadores">Verificadores</span>
          </Divider>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2" v-show="visibleInfoChequeadores">
        <div class="col-span-12 flex">
          <div v-if="dataSolicitud.chequeadores.length <= 0" class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-grow flex-col p-my-lg-0 pr-3 items-center justify-between p-2 rounded-lg bg-gray-50 w-full">
              <svg class="opacity-20" height="100px" width="100px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 512 512" xml:space="preserve">
    <path style="fill:#FFFFFF;" d="M494.012,369.545c9.199,72.482-51.985,133.675-124.467,124.467
    c-49.399-6.272-89.293-46.165-95.565-95.565c-9.19-72.474,51.994-133.658,124.476-124.459
    C447.855,280.26,487.74,320.145,494.012,369.545z"/>
                <path style="fill:#F0F0F0;" d="M401.553,274.441c-31.275-4.873-60.459,3.567-83.166,20.309c19.115-5.666,40.286-6.537,62.31-0.222
    c40.038,11.486,70.963,45.261,78.285,86.263c7.987,44.74-11.11,85.658-43.503,109.534c49.681-14.729,84.932-63.309,78.754-118.946
    C488.789,322.398,450.244,282.027,401.553,274.441z"/>
                <path d="M264.533,477.867H25.6c-3.866,0-8.533-3.806-8.533-8.533v-25.6c0-4.71-3.814-8.533-8.533-8.533S0,439.023,0,443.733v25.6
    c0,13.636,11.964,25.6,25.6,25.6h238.933c4.719,0,8.533-3.823,8.533-8.533S269.252,477.867,264.533,477.867z"/>
                <path d="M8.533,384c4.719,0,8.533-3.823,8.533-8.533v-230.4c0-4.702,3.823-8.533,8.533-8.533h443.733
    c4.71,0,8.533,3.831,8.533,8.533v119.467c0,4.71,3.814,8.533,8.533,8.533s8.533-3.823,8.533-8.533V145.067
    c0-14.114-11.486-25.6-25.6-25.6V76.8c0-14.114-11.486-25.6-25.6-25.6H261.692c-1.843,0-3.661-0.606-5.129-1.707L197.402,5.12
    C193.007,1.818,187.554,0,182.042,0H51.2C37.086,0,25.6,11.486,25.6,25.6v93.867c-14.114,0-25.6,11.486-25.6,25.6v230.4
    C0,380.177,3.814,384,8.533,384z M409.6,119.467H85.333v-8.533c0-4.702,3.823-8.533,8.533-8.533h307.2
    c4.71,0,8.533,3.831,8.533,8.533V119.467z M42.667,25.6c0-4.702,3.823-8.533,8.533-8.533h130.842c1.843,0,3.661,0.606,5.129,1.707
    l59.162,44.373c4.395,3.302,9.847,5.12,15.36,5.12h182.042c4.71,0,8.533,3.831,8.533,8.533v42.667h-25.6v-8.533
    c0-14.114-11.486-25.6-25.6-25.6h-307.2c-14.114,0-25.6,11.486-25.6,25.6v8.533h-25.6V25.6z"/>
                <path d="M384,256c-70.579,0-128,57.421-128,128s57.421,128,128,128s128-57.421,128-128S454.579,256,384,256z M273.067,384
    c0-61.167,49.766-110.933,110.933-110.933c27.52,0,52.676,10.129,72.081,26.786L299.853,456.081
    C283.196,436.676,273.067,411.52,273.067,384z M384,494.933c-27.52,0-52.676-10.129-72.081-26.786L468.156,311.91
    c16.649,19.413,26.778,44.57,26.778,72.09C494.933,445.167,445.167,494.933,384,494.933z"/>
                <path d="M102.4,59.733c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533H68.267c-4.719,0-8.533,3.823-8.533,8.533
    s3.814,8.533,8.533,8.533H102.4z"/>
                <path d="M170.667,59.733h8.533c4.719,0,8.533-3.823,8.533-8.533s-3.814-8.533-8.533-8.533h-8.533c-4.719,0-8.533,3.823-8.533,8.533
    S165.948,59.733,170.667,59.733z"/>
                <circle cx="136.533" cy="51.2" r="8.533"/>
                <circle cx="8.533" cy="409.6" r="8.533"/>
                <path d="M128,324.267c4.71,0,8.533-3.823,8.533-8.533c0-46.473,22.588-70.221,30.976-78.635c3.738,1.075,7.612,1.835,11.691,1.835
    c4.079,0,7.953-0.759,11.691-1.835c8.388,8.414,30.976,32.162,30.976,78.635c0,4.71,3.823,8.533,8.533,8.533
    s8.533-3.823,8.533-8.533c0-47.787-21.018-74.863-32.375-86.997c9.276-7.834,15.309-19.405,15.309-32.469
    c0-23.526-19.14-42.667-42.667-42.667s-42.667,19.14-42.667,42.667c0,13.065,6.033,24.636,15.309,32.469
    c-11.358,12.134-32.375,39.211-32.375,86.997C119.467,320.444,123.29,324.267,128,324.267z M179.2,170.667
    c14.114,0,25.6,11.486,25.6,25.6c0,14.114-11.486,25.6-25.6,25.6c-14.114,0-25.6-11.486-25.6-25.6
    C153.6,182.153,165.086,170.667,179.2,170.667z"/>
                <path d="M281.6,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
    c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533
    c0-7.68,0.043-13.278,5.683-19.166c3.575,1.28,7.373,2.099,11.383,2.099s7.808-0.819,11.383-2.099
    c5.641,5.888,5.683,11.486,5.683,19.166c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
    c5.265-6.007,8.567-13.773,8.567-22.374C315.733,177.442,300.425,162.133,281.6,162.133z M281.6,213.333
    c-9.412,0-17.067-7.654-17.067-17.067S272.188,179.2,281.6,179.2c9.412,0,17.067,7.654,17.067,17.067S291.012,213.333,281.6,213.333
    z"/>
                <path d="M76.8,162.133c-18.825,0-34.133,15.309-34.133,34.133c0,8.602,3.302,16.367,8.567,22.374
    c-8.465,10.146-8.567,20.42-8.567,28.826c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533c0-7.68,0.043-13.278,5.683-19.166
    c3.575,1.28,7.373,2.099,11.383,2.099c4.011,0,7.808-0.819,11.383-2.099c5.641,5.888,5.683,11.486,5.683,19.166
    c0,4.71,3.823,8.533,8.533,8.533c4.71,0,8.533-3.823,8.533-8.533c0-8.405-0.102-18.679-8.567-28.826
    c5.265-6.007,8.567-13.773,8.567-22.374C110.933,177.442,95.625,162.133,76.8,162.133z M76.8,213.333
    c-9.412,0-17.067-7.654-17.067-17.067S67.388,179.2,76.8,179.2s17.067,7.654,17.067,17.067S86.212,213.333,76.8,213.333z"/>
                <g>
    <path style="fill:#CC004E;" d="M300.629,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
    c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133s7.851-0.853,11.349-2.133c5.717,5.888,5.717,11.52,5.717,19.2
    C298.667,249.515,299.435,251.477,300.629,252.928z"/>
                  <circle style="fill:#CC004E;" cx="281.6" cy="196.267" r="17.067"/>
    </g>
                <g>
    <path style="fill:#0F7BE4;" d="M229.12,324.181c-11.008,34.219-88.832,34.219-99.84,0c4.096-0.683,7.253-4.181,7.253-8.448
    c0-46.507,22.613-70.229,30.976-78.677c3.755,1.109,7.595,1.877,11.691,1.877s7.936-0.768,11.691-1.877
    c8.363,8.448,30.976,32.171,30.976,78.677C221.867,320,225.024,323.499,229.12,324.181z"/>
                  <circle style="fill:#0F7BE4;" cx="179.2" cy="196.267" r="25.6"/>
    </g>
                <path style="fill:#FDB50D;" d="M409.6,110.933v8.533H85.333v-8.533c0-4.693,3.84-8.533,8.533-8.533h307.2
    C405.76,102.4,409.6,106.24,409.6,110.933z"/>
                <path style="fill:#AFAFAF;" d="M477.867,264.533V145.067c0-4.693-3.84-8.533-8.533-8.533H25.6c-4.693,0-8.533,3.84-8.533,8.533
    v230.4c0,4.693-3.84,8.533-8.533,8.533v17.067c4.693,0,8.533,3.84,8.533,8.533c0,4.693-3.84,8.533-8.533,8.533V435.2
    c4.693,0,8.533,3.84,8.533,8.533v25.6c0,4.693,4.693,8.533,8.533,8.533h238.933c4.693,0,8.533,3.84,8.533,8.533h34.219
    C276.139,463.019,256,425.813,256,384c0-70.571,57.429-128,128-128c41.813,0,79.019,20.139,102.4,51.285v-34.219
    C481.707,273.067,477.867,269.227,477.867,264.533z M110.933,247.467c0,4.693-3.84,8.533-8.533,8.533
    c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C56.491,254.635,54.016,256,51.2,256
    c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
    c0-18.859,15.275-34.133,34.133-34.133s34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
    C110.848,228.779,110.933,239.104,110.933,247.467z M230.4,324.267c-0.427,0-0.853,0-1.28-0.085
    c-11.008,34.219-88.832,34.219-99.84,0c-0.427,0.085-0.853,0.085-1.28,0.085c-4.693,0-8.533-3.84-8.533-8.533
    c0-47.787,20.992-74.837,32.341-87.04c-9.216-7.765-15.275-19.371-15.275-32.427c0-23.552,19.115-42.667,42.667-42.667
    s42.667,19.115,42.667,42.667c0,13.056-6.059,24.661-15.275,32.427c11.349,12.203,32.341,39.253,32.341,87.04
    C238.933,320.427,235.093,324.267,230.4,324.267z M315.733,247.467c0,4.693-3.84,8.533-8.533,8.533
    c-2.645,0-4.949-1.195-6.571-3.072c-15.189,10.496-29.696,4.864-37.803-0.341C261.291,254.635,258.816,256,256,256
    c-4.693,0-8.533-3.84-8.533-8.533c0-8.363,0.085-18.688,8.533-28.843c-5.205-5.973-8.533-13.739-8.533-22.357
    c0-18.859,15.275-34.133,34.133-34.133c18.859,0,34.133,15.275,34.133,34.133c0,8.619-3.328,16.384-8.533,22.357
    C315.648,228.779,315.733,239.104,315.733,247.467z"/>
                <g>
    <circle style="fill:#CC004E;" cx="76.8" cy="196.267" r="17.067"/>
                  <path style="fill:#CC004E;" d="M95.829,252.928c-15.189,10.496-29.696,4.864-37.803-0.341c1.024-1.451,1.707-3.243,1.707-5.12
    c0-7.68,0-13.312,5.717-19.2c3.499,1.28,7.339,2.133,11.349,2.133c4.011,0,7.851-0.853,11.349-2.133
    c5.717,5.888,5.717,11.52,5.717,19.2C93.867,249.515,94.635,251.477,95.829,252.928z"/>
    </g>
                <path style="fill:#7E939E;" d="M477.867,264.448V144.981c0-4.693-3.84-8.533-8.533-8.533h-25.6v111.019
    c-12.117-5.12-25.344-8.107-39.168-8.533c-1.195-0.085-2.304-0.085-3.499-0.085c-15.104,0-29.525,3.072-42.667,8.533h-0.085
    c-13.653,5.717-25.941,14.08-36.096,24.491c5.035-2.816,10.325-5.291,15.701-7.339c14.251-5.547,29.781-8.619,46.08-8.619
    c21.589,0,41.899,5.376,59.733,14.848c16.811,8.789,31.317,21.333,42.667,36.437v-34.219
    C481.707,272.981,477.867,269.141,477.867,264.448z"/>
                <path style="fill:#F6981E;" d="M401.067,102.4h-34.133c4.693,0,8.533,3.84,8.533,8.533v8.533H409.6v-8.533
    C409.6,106.24,405.76,102.4,401.067,102.4z"/>
                <g>
    <path style="fill:#215EFF;" d="M179.2,170.667c-0.137,0-0.273,0.043-0.41,0.043c10.103,3.43,17.476,12.919,17.476,24.149
    c0,13.935-11.298,25.327-25.19,25.557c2.569,0.87,5.274,1.451,8.124,1.451c14.08,0,25.6-11.52,25.6-25.6
    S193.28,170.667,179.2,170.667z"/>
                  <path style="fill:#215EFF;" d="M215.014,325.794c0,4.745,3.14,8.619,7.228,9.463c3.132-3.174,5.521-6.861,6.878-11.076
    c-4.096-0.683-7.253-4.181-7.253-8.448c0-46.507-22.613-70.229-30.976-78.677c-2.022,0.597-4.079,1.067-6.178,1.399
    C193.997,249.02,215.014,275.635,215.014,325.794z"/>
    </g>
                <g>
    <path style="fill:#9B0045;" d="M291.337,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
    c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
    C291.635,234.854,291.337,241.041,291.337,247.467z"/>
                  <path style="fill:#9B0045;" d="M282.223,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
    c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
    C298.667,187.093,291.319,179.601,282.223,179.26z"/>
                  <path style="fill:#9B0045;" d="M86.537,247.467c0,1.724,2.21,6.332,3.644,8.678c1.869-0.845,3.755-1.903,5.658-3.217
    c-1.203-1.451-1.971-3.413-1.971-5.461c0-7.68,0-13.312-5.717-19.2c-1.451,0.529-2.987,0.93-4.54,1.28
    C86.835,234.854,86.537,241.041,86.537,247.467z"/>
                  <path style="fill:#9B0045;" d="M77.423,179.26c5.658,2.782,9.617,8.567,9.617,15.266c0,9.387-7.68,17.067-17.067,17.067
    c-0.213,0-0.418-0.06-0.623-0.06c2.261,1.118,4.77,1.801,7.45,1.801c9.387,0,17.067-7.68,17.067-17.067
    C93.867,187.093,86.519,179.601,77.423,179.26z"/>
    </g>
                <path style="fill:#7E939E;" d="M443.733,68.267H261.717c-5.547,0-11.008-1.792-15.36-5.12l-59.221-44.373
    c-1.451-1.109-3.243-1.707-5.12-1.707H51.2c-4.693,0-8.533,3.84-8.533,8.533v93.867h25.6v-8.533c0-14.08,11.52-25.6,25.6-25.6h307.2
    c14.08,0,25.6,11.52,25.6,25.6v8.533h25.6V76.8C452.267,72.107,448.427,68.267,443.733,68.267z M102.4,59.733H68.267
    c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533H102.4c4.693,0,8.533,3.84,8.533,8.533S107.093,59.733,102.4,59.733z
     M136.533,59.733c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533s8.533,3.84,8.533,8.533S141.227,59.733,136.533,59.733z
     M179.2,59.733h-8.533c-4.693,0-8.533-3.84-8.533-8.533s3.84-8.533,8.533-8.533h8.533c4.693,0,8.533,3.84,8.533,8.533
    S183.893,59.733,179.2,59.733z"/>
    </svg>
              <span class="text-red-900 opacity-20 font-bold">No hay verificadores aún</span>
            </div>
          </div>
          <div v-else class="w-full">
            <div v-for="(chequeador, index) in dataSolicitud.chequeadores" :key="index">
              <div class="flex flex-col flex-grow w-full justify-center p-2 rounded-lg bg-gray-50 mb-2">
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Nombre: </span>
                  <span class="text-gray-700 text-sm">{{ chequeador.full_name }}</span>
                </div>
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Observación: </span>
                  <span class="text-gray-700 text-sm">{{ chequeador.observaciones }}</span>
                </div>
                <div class="flex flex-wrap justify-between pt-1">
                  <span class="text-gray-500 text-xs mr-2">Fecha: </span>
                  <span class="text-gray-700 text-sm">{{ $h.formatDate(chequeador.fechaChequeo, 'YYYY-MM-DD HH:mm:ss') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Sidebar>
  </div>
</template>

<style scoped>
.water-maker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(331deg);
  font-size: 5rem;
  opacity: 0.06;
  font-family: 'Denk One', sans-serif;
  text-transform: uppercase;
}
</style>
