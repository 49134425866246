<script setup>
import { ref, defineExpose, onMounted } from 'vue'
import FilePond from '../components/uploadFilePond/index.vue'
import SoporteService from '../../services/soporte.service'
import Swal from 'sweetalert2'
import { error, messageWarningDelete, warning } from '../../../../../../libs/mensajes'
import { useToast } from 'primevue/usetoast'

const toast = useToast()
const _SoporteService = ref(new SoporteService())
const displayModal = ref(false)
const infoSolicitud = ref({})
const supports = ref([])
const pondFile = ref(null)
const descriptionSupport = ref('')
const urlSave = ref(process.env.VUE_APP_MICROSERVICE_API_SOLICITUD_PERMISOS)

// Funcion para abrir el modal
const openModal = (values) => {
  displayModal.value = true
  descriptionSupport.value = null
  infoSolicitud.value = values
  fetchSupportsBySolicitud(values.id)
}

/* Cerrar el modal */
const closeModalSupports = () => {
  displayModal.value = false
  descriptionSupport.value = null
}

/* Obtener la información de los soportes por ID de la solicitud */
const fetchSupportsBySolicitud = async (solicitudId) => {
  await _SoporteService.value.listarSoportesPorSolicitud(solicitudId).then(({ status, data: response }) => {
    if (status !== 200) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Problemas al listar los soportes de la solicitud, consulte con el administrador del sistema'
      })
    } else {
      supports.value = response
    }
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

/* Funcion para subir los archivos del componente filepond */
const submitSupport = (solicitudId) => {
  pondFile.value.onSubmitSupport().then(async () => {
    await fetchSupportsBySolicitud(solicitudId).then(async () => {
      await pondFile.value.onRemoveSupport()
      descriptionSupport.value = null
    })
  })
}

/* Visualizar o descargar soportes */
const onShowSupport = async (adjunto) => {
  await _SoporteService.value.descargarSoporte(adjunto).then((url) => {
    if (adjunto !== null) {
      window.open(url, '_blank')
    } else {
      warning('No hay soporte')
    }
  }).catch((err) => {
    console.error(err.message)
    error(err.response.data.message)
  })
}

/* Eliminar el archivo y dato del soporte */
const onDeleteSupport = (supportId) => {
  messageWarningDelete('', 'Eliminar Soporte', 'warning', '¿Esta seguro de eliminar el soporte, esta acción es irreversible?').then(({ isConfirmed }) => {
    if (isConfirmed) {
      _SoporteService.value.eliminarSoporte(supportId).then(() => {
        toast.add({ severity: 'success', summary: 'Exito', detail: 'Soporte eliminado correctamente', life: 3000 })
        fetchSupportsBySolicitud(infoSolicitud.value.id)
      }).catch((err) => {
        console.error(err.message)
        error(err.response.data.message)
      })
    }
  })
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  console.log('onMounted, modal soportes...')
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '60vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Soportes de la solicitud #${infoSolicitud.id}` }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid gap-4 bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-5 xl:grid-cols-5 xxl:grid-cols-5">
      <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
        <p class="mb-4">
          Listado de los soportes
        </p>
        <DataTable
          :value="supports"
          responsiveLayout="stack"
          breakpoint="960px"
          class="p-datatable-sm"
          showGridlines
        >
          <template #empty> No hay datos para mostrar. </template>
          <template #loading> Cargando la información, por favor espere... </template>
          <Column field="id" header="ID"></Column>
          <Column field="name" header="Nombre">
            <template #body="slotProps">
              <span>Soporte {{ slotProps.data.id }}</span>
            </template>
          </Column>
          <Column field="description" header="Comentario">
            <template #body="{ data }">
              <span>{{ [undefined, null, 'null', "0", 0].includes(data.descripcion) ? '' : data.descripcion }}</span>
            </template>
          </Column>
          <Column field="createdAt" header="Fecha">
            <template #body="{ data }">
              <span>{{ data.createdAt.substring(0, 10) }}</span>
            </template>
          </Column>
          <Column field="" header="Acciones">
            <template #body="slotProps">
              <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-xs p-button-default p-mr-2" @click="onShowSupport(slotProps.data.soporte)" v-tooltip.top="'Ver o descargar el soporte'"/>
              <Button icon="pi pi-trash" class="p-button-rounded p-button-xs p-button-danger" @click="onDeleteSupport(slotProps.data.id)" v-tooltip.top="'Eliminar el soporte'"/>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="sm:col-span-2 lg:col-span-5 xl:col-span-5 xxl:col-span-5">
        <p class="mb-4">
          Cargue del soporte:
        </p>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12">
            <FilePond ref="pondFile" :solicitudId="infoSolicitud.id" :description="descriptionSupport" :url="`${urlSave}/soporte`"/>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <label for="name">Comentario:</label>
            <Textarea
              v-model="descriptionSupport"
              id="description"
              :autoResize="true"
              rows="2"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalSupports" class="p-button-xs p-button-danger"/>
      <Button label="Guardar" icon="pi pi-check" @click="submitSupport(infoSolicitud.id)" class="p-button-xs p-button-success" autofocus />
    </template>

  </Dialog>

</template>

<style scoped>

</style>
