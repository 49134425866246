<script setup>
import { onMounted, ref, reactive, watch } from 'vue'
import { useFiltersSolicitudPermisosStore } from '../../../stores/filtersSolicitudPermisos'
import SolicitudService from '../../../services/solicitud.service'
import EmpleadoService from '../../../services/empleado.service'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'

const _SolicitudService = ref(new SolicitudService())
const _EmpleadoService = ref(new EmpleadoService())
const filtersSolicitudPermisosStore = useFiltersSolicitudPermisosStore()
const listMotivos = ref([])
const listCargos = ref([])
const listAreas = ref([])
const listEstados = ref([
  { id: '', name: 'Todos' },
  { id: 'APROBADO', name: 'Aprobado' },
  { id: 'RECHAZADO', name: 'Rechazado' },
  { id: 'PENDIENTE', name: 'Pendiente' },
  { id: 'CANCELADO', name: 'Cancelado' },
  { id: 'SIN ENVIAR', name: 'Sin enviar' },
  { id: 'SIN APROBADOR', name: 'Sin Aprobador' }
])
const listTipoFecha = ref([
  { id: 'FECHA_CREACION', name: 'Fecha Creación' },
  { id: 'FECHA_ENVIO', name: 'Fecha Envió' }
])
const filters = reactive({
  nombre: '',
  cedula: '',
  area: '',
  cargo: '',
  motivo: '',
  tipoFecha: '',
  fechaInicial: '',
  fechaFinal: '',
  estadoSolicitud: ''
})

// Obtener las áreas
const fetchAreas = async () => {
  await _EmpleadoService.value.listarAreas().then((res) => {
    listAreas.value = res.data
    // Agregar el item de todos
    listAreas.value.unshift({ id: '', name: 'Todos' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Obtener los motivos
const fetchMotivos = async () => {
  await _SolicitudService.value.listarMotivos().then((res) => {
    listMotivos.value = res.data
    // Agregar el item de todos
    listMotivos.value.unshift({ id: '', nombre: 'Todos' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Obtener los cargos
const fetchCargos = async () => {
  await _EmpleadoService.value.listarCargos().then((res) => {
    listCargos.value = res.data
    // Agregar el item de todos
    listCargos.value.unshift({ id: '', name: 'Todos' })
  }).catch((err) => {
    console.log('Error ->', err)
  })
}

// Si se selecciona el tipo de fecha se habilita el filtro de fecha inicial con el primer día del mes actual y el filtro de fecha final con el último día del mes actual
watch(() => filters.tipoFecha, (value) => {
  if (value) {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    filters.fechaInicial = firstDay.toISOString().slice(0, 10)
    filters.fechaFinal = lastDay.toISOString().slice(0, 10)
  } else {
    filters.fechaInicial = ''
    filters.fechaFinal = ''
  }
})

// Validar que la fecha final no sea menor a la fecha inicial
const validateFechaFinal = (fechaInicial, fechaFinal) => {
  const fecIni = dayjs(fechaInicial)
  const fecFin = dayjs(fechaFinal)
  if (fecFin.isBefore(fecIni)) {
    Swal.fire({
      icon: 'error',
      title: 'Validación',
      text: 'La fecha final no puede ser menor a la fecha inicial'
    })
    return true
  } else {
    return false
  }
}

// Realizar la búsqueda y setear los filtros en el store
const updateFilter = () => {
  if (validateFechaFinal(filters.fechaInicial, filters.fechaFinal)) {
    return
  }

  const filtersData = {
    nombre: filters.nombre ?? '',
    cedula: filters.cedula ?? '',
    area: filters.area ?? '',
    cargo: filters.cargo ?? '',
    motivo: filters.motivo ?? '',
    tipoFecha: filters.tipoFecha ?? '',
    fechaInicial: filters.fechaInicial ?? '',
    fechaFinal: filters.fechaFinal ?? '',
    estadoSolicitud: filters.estadoSolicitud ?? ''
  }

  filtersSolicitudPermisosStore.setUpdateFilters(filtersData)
}

// Limpiar los filtros
const resetClear = () => {
  for (const [key] of Object.entries(filtersSolicitudPermisosStore.getFilters)) {
    filters[key] = ''
  }
  filtersSolicitudPermisosStore.setUpdateFilters(filters)
}

// Exportar a excel
/* const exportExcel = (filters) => {
  console.log('filters', filters)
} */

const reload = () => {
  filtersSolicitudPermisosStore.setRefrescarListadoSolicitudes(true)
}

// Hook onMounted
onMounted(async () => {
  await fetchMotivos()
  await fetchCargos()
  await fetchAreas()

  // Setear los filtros del store al filters reactive
  for (const [key, value] of Object.entries(filtersSolicitudPermisosStore.getFilters)) {
      filters[key] = value
  }
})
</script>

<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2">

    <div class="col-span-12 sm:col-span-2">
      <label for="tipoFecha" class="block text-xs font-medium text-gray-700 mb-2">Tipo fecha:</label>
      <Dropdown
        id="tipoFecha"
        v-model="filters.tipoFecha"
        :options="listTipoFecha"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.tipoFecha ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>

    <div class="col-span-12 sm:col-span-2">
      <label for="fechaInicial" class="block text-xs font-medium text-gray-700 mb-2">Fecha Inicial:</label>
      <InputText
        id="fechaInicial"
        type="date"
        class="w-full text-sm"
        :disabled="!filters.tipoFecha"
        v-model="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaInicial ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="fechaFinal" class="block text-xs font-medium text-gray-700 mb-2">Fecha Final:</label>
      <InputText
        id="fechaFinal"
        type="date"
        class="w-full text-sm"
        :disabled="!filters.tipoFecha"
        v-model="filters.fechaFinal"
        :min="filters.fechaInicial"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.fechaFinal ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="cedula" class="block text-xs font-medium text-gray-700 mb-2">Cedula:</label>
      <InputText
        id="cedula"
        type="number"
        class="w-full text-sm"
        placeholder="Buscar por cedula..."
        v-model="filters.cedula"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.cedula ? 'border-color: #689f38' : '')
          }),
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Area:</label>
      <Dropdown
        id="motivo"
        v-model="filters.area"
        :options="listAreas"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.area ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Cargo:</label>
      <Dropdown
        id="motivo"
        v-model="filters.cargo"
        :options="listCargos"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.cargo ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="motivo" class="block text-xs font-medium text-gray-700 mb-2">Motivo:</label>
      <Dropdown
        id="motivo"
        v-model="filters.motivo"
        :options="listMotivos"
        optionLabel="nombre"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        scrollHeight="auto"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.motivo ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="estado" class="block text-xs font-medium text-gray-700 mb-2">Estado:</label>
      <Dropdown
        id="estado"
        v-model="filters.estadoSolicitud"
        :options="listEstados"
        optionLabel="name"
        optionValue="id"
        placeholder="Seleccione una opción"
        :filter="true"
        filterPlaceholder="Buscar por nombre"
        class="w-full text-sm"
        :pt="{
          root: ({
            props, state, context
          }) => ({
            style: 'height: 30px;' + ($route.query.estadoSolicitud ? 'border-color: #689f38' : '')
          }),
          input: { style: 'font-size: 12px; padding: 0.25rem 0.5rem' },
          panel: { style: 'font-size: 12px' },
          item: { style: 'padding: 0.5rem 1rem' }
        }"
      />
    </div>
    <div class="col-span-12 lg:col-span-12">
      <div class="flex flex-wrap justify-end gap-2 w-full">
        <Button
          icon="pi pi-search"
          severity="primary"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="updateFilter"
          v-tippy="{ content: 'Buscar' }"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="resetClear"
          v-tippy="{ content: 'Limpiar Filtros' }"
        />
        <Button
          icon="pi pi-refresh text-sm"
          severity="success"
          class="p-button-xs w-full"
          style="height: 30px"
          @click="reload()"
          v-tippy="{ content: 'Recargar' }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
