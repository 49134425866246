<script setup>
import { onMounted, ref, defineExpose, computed } from 'vue'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useStore } from 'vuex'
import { error, messageConfirmSave } from '../../../../../../libs/mensajes'
import SolicitudService from '../../services/solicitud.service'
import Swal from 'sweetalert2'
import { useFiltersSolicitudPermisosStore } from '../../stores/filtersSolicitudPermisos'

const filtersSolicitudPermisosStore = useFiltersSolicitudPermisosStore()
const _SolicitudService = ref(new SolicitudService())
const store = useStore()
const displayModal = ref(false)
const status = ref(null)
const infoSolicitud = ref({})
const listDescontable = ref(['SI', 'NO'])

// Obtener la información del usuario
const getPeople = computed(() => {
  return store.getters['auth/getPeople']
})

const validationSchema = yup.object({
  observaciones: yup.string().required('La observación es requerida').label(''),
  // Descontable solo es requerido cuando el estado es aprobado
  descontable: yup.string().when('estado', {
    is: 'APROBAR',
    then: yup.string().required('El permiso descontable es requerido').label('')
  })
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

useField('observaciones', null, { initialValue: '' })
useField('descontable', null, { initialValue: '' })

//
const onSubmit = handleSubmit(async (values) => {
  // Cambiar el valor de descontable a boolean
  values.descontable = values.descontable === 'SI'

  messageConfirmSave('', 'Información', 'warning', infoEstado.value.mensaje).then(({ isConfirmed }) => {
    if (isConfirmed) {
      _SolicitudService.value.crearAprobacion(model).then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Exito',
          text: infoEstado.value.mensajeEnviado
        }).then(() => {
          closeModal()
          // Recargar la tabla de solicitudes
          filtersSolicitudPermisosStore.setRefrescarListadoSolicitudes(true)
        })
      }).catch((err) => {
        console.error(err.message)
        if (err.response.status) {
          error(err.response.data.message)
        }
      })
    }
  })
})

// Funcion para abrir el modal
const openModal = async (data, estado) => {
  console.log('openModal, aprobaciones..', data)

  displayModal.value = true
  status.value = estado

  model.solicitudPermisoId = data.id
  model.estado = estado
  model.aprobadorId = getPeople.value.id
  model.aprobadorNombre = getPeople.value.full_name

  infoSolicitud.value = data
}

// Establecer valores dependiendo del estado
const infoEstado = computed(() => {
  // Estados: APROBAR, RECHAZAR, CANCELAR
  switch (status.value) {
    case 'APROBAR':
      return {
        color: 'text-green-600',
        icon: 'pi pi-check-circle',
        text: 'Aprobar',
        mensaje: '¿Esta seguro de aprobar la solicitud de permiso?',
        mensajeEnviado: 'La solicitud de permiso se ha aprobado correctamente'
      }
    case 'RECHAZAR':
      return {
        color: 'text-red-600',
        icon: 'pi pi-times-circle',
        text: 'Rechazar',
        mensaje: '¿Esta seguro de rechazar la solicitud de permiso?',
        mensajeEnviado: 'La solicitud de permiso se ha rechazado correctamente'
      }
    case 'CANCELAR':
      return {
        color: 'text-yellow-600',
        icon: 'pi pi-exclamation-circle',
        text: 'Cancelar',
        mensaje: '¿Esta seguro de cancelar la solicitud de permiso?',
        mensajeEnviado: 'La solicitud de permiso se ha cancelado correctamente'
      }
    default:
      return {
        color: 'text-gray-600',
        icon: 'pi pi-info-circle',
        text: 'Información'
      }
  }
})

/* Cerrar el modal */
const closeModal = () => {
  displayModal.value = false
  handleReset()
}

// Se expone la funcion para abrir el modal, para que pueda ser llamado desde el componente padre
defineExpose({
  openModal
})

// Hook onMounted
onMounted(() => {
  handleReset()
  console.log('onMounted, modal aprobacion...')
})
</script>

<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="false"
    :closable="false"
    :breakpoints="{'960px': '95vw'}"
    :style="{width: '35vw'}"
    :pt="{
      header: {
        style: 'padding: 1rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div
        class="flex items-center text-xl font-bold"
        :class="`${infoEstado.color}`"
      >
        <i :class="`${infoEstado.icon} mr-2`"></i>
        <h4>{{ `${infoEstado.text } Solicitud # ${infoSolicitud.id} ` }} </h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <div class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">

      <div class="sm:col-span-6">
        <label for="observaciones" class="block text-xs font-medium text-gray-700 mb-2">Observaciones:</label>
        <Textarea
            id="observaciones"
            v-model="model.observaciones"
            rows="5"
            cols="30"
            class="w-full"
            :class="{ 'p-invalid': errors.observaciones }"
        />
        <MessageError :text="errors.observaciones" />
      </div>
      <!-- Descontable -->
      <div class="sm:col-span-3" v-if="infoEstado.text === 'Aprobar'">
        <label for="tipoTiempo" class="block text-xs font-medium text-gray-700 mb-2">Permiso Descontable?:</label>
        <div class="card flex justify-content-center">
          <div class="flex flex-column gap-3">
            <div v-for="descontable in listDescontable" :key="descontable" class="flex align-items-center">
              <RadioButton
                  v-model="model.descontable"
                  :inputId="descontable"
                  name="tipoTiempo"
                  :value="descontable"
                  :class="{ 'p-invalid': errors.descontable }"
              />
              <label :for="descontable" class="ml-2">{{ descontable }}</label>
            </div>
          </div>
        </div>
        <MessageError :text="errors.descontable" />
      </div>
    </div>

    <!-- Footer del modal -->
    <template #footer>
      <Button
        label="Cerrar"
        severity="secondary"
        icon="pi pi-times"
        @click="closeModal"
        class="p-button-xs"
      />
      <Button
        label="Enviar"
        severity="success"
        icon="pi pi-send"
        @click="onSubmit"
        class="p-button-xs"
        autofocus
      />
    </template>
  </Dialog>
</template>

<style scoped>

</style>
